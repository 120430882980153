export const ROLE_BLOCKS = [
  {
    name: 'common.roles.dietetics',
    permissionTitle: [
      'SIZE',
      'MEAL_TYPE',
      'DIET',
      'VARIANT',
      'PACKAGE',
      'PACKAGE_LINE_FILTER',
      'PRODUCT',
      'INGREDIENT',
      'INGREDIENT_PRICE',
      'INGREDIENT_TRANSLATIONS',
      'RECIPE',
      'DISH',
      'KWG_GROUP',
      'DISH_PHOTO_GALLERY',
      'DISH_TRANSLATIONS',
      'MASS_ACTION',
      'SYSTEM_ADVANCED_DISH',
      'MODULE_DISH_PDF_GENERATOR',
      'MENU_PLANNER',
      'MENU_PLANNER_AUTO_FILL',
      'MENU_SUMMARY',
    ],
  },
  {
    name: 'common.roles.production',
    permissionTitle: [
      // 'TASKS_GENERATOR',
      // 'KITCHEN',
      // 'DISH_PACKHOUSE',
      // 'BAGS_PACKHOUSE',
      'BAG_CONTAINER',
      // 'WASTE',
    ],
    subRoleSection: {
      title: 'common.rolesForm.subRoleSection.title.report.production',
      options: [
        'REPORT_SHOPPING',
        'REPORT_PURCHASED_ADDONS',
        'REPORT_PURCHASED_ADDONS_BY_CLIENT',
        'REPORT_CONTAINERS',
        'REPORT_CONTAINERS_DETAIL',
        'REPORT_CHECKLIST_RECIPES',
        'REPORT_RECIPES_CARD',
        'REPORT_RECIPES_INGREDIENTS',
        'REPORT_CHECKLIST_DISHES',
        'REPORT_DISHES_CARD',
        'REPORT_DISH_STICKERS',
        'REPORT_BAG_STICKERS_MINI',
        'REPORT_DISH_LIST',
        'REPORT_CHECKLIST_DIETS',
        'REPORT_CHECKLIST_ALTERED_DIETS',
        'REPORT_ACTIVE_DIET',
        'REPORT_BAG_STICKERS',
        'REPORT_PERSONAL_PICKUPS',
        'REPORT_PACKERS',
        'REPORT_FOR_DRIVER',
        'REPORT_RECIPE_STICKERS',
        'REPORT_TOTAL_BEL',
        'REPORT_ADVANCED_DISH_PDF',
        // 'REPORT_WASTE',
        'REPORT_OVERPRODUCTION',
        'ROLE_SHOW_REPORT_SELECTIONS',
      ],
    },
  },
  {
    name: 'common.roles.logistic',
    permissionTitle: [
      'ZONE_CATEGORY',
      'ZONE',
      'ADDRESS',
      'DRIVER',
      'PICK_UP_POINT',
      'ROUTE_POINT',
      'ROUTE_MANAGER',
      'ADDRESS_REQUESTS',
    ],
  },
  {
    name: 'common.roles.customerService',
    permissionTitle: [
      'USER',
      'BASKET',
      'ORDER',
      'BAG',
      'RATE',
      'MONEY_BOX_HISTORY',
      'IMPERSONATE_USER',
    ],
  },
  {
    name: 'common.roles.communication',
    permissionTitle: [
      'CLIENT_CALL',
      'SMS_CONFIGURATION',
      'MAIL_CONFIGURATION',
      'END_OF_DIET_REMINDER',
      'END_OF_DIET_REMINDER_SMS',
      'SMSING',
      'END_OF_DIET_REMINDER_MAIL',
      'MAILING',
      'SMS',
      'EMAIL',
      'MASS_SMS',
      'MASS_MAIL',
    ],
  },
  {
    name: 'common.roles.clientPanelOption',
    permissionTitle: [
      'COST',
      'DISCOUNT_CODE',
      'MONEY_BOX_CONFIGURATION',
      // 'MODULE_MENU_SELECTION_ASSISTANT',
      'USE_MINI_ADDRESS_FORM',
      'HOVER',
      'NOTIFICATION',
      'SEO',
      'SHOW_MENU_PAGE',
      'CLIENT_METRIC',
    ],
  },
  {
    name: 'common.roles.myCompany',
    permissionTitle: ['COMPANY', 'ROLE', 'EMPLOYEE', 'REPORT_ARCHIVE'],
  },
  {
    name: 'common.roles.brandConfiguration',
    permissionTitle: [
      'BRAND',
      'BRAND_POWER_BI',
      'BRAND_ACCESS_KEY',
      'FEED',
      'NOTE',
      'IMPORTER_TASK',
      'CONTRACTOR',
      'DICTIONARY',
      // 'MODULE_SETTLEMENTS',
      'STATISTICS_ECOMMERCE_CALCULATION_DATA',
      'TRANSLATION',
      'COPY_BRAND_CONFIGURATION',
    ],
    subRoleSection: {
      title: 'common.rolesForm.subRoleSection.title.statistics',
      options: [
        'STATISTICS_DIET',
        'STATISTICS_DIET_INCOMES',
        'STATISTICS_DIET_SEX',
        'STATISTICS_DIET_KNOW_ABOUT',
        'STATISTICS_ECOMMERCE_DAY_ORDERS',
        'STATISTICS_ECOMMERCE_DAY_BAGS',
        'STATISTICS_ECOMMERCE_DAY_INCOME',
        'STATISTICS_CORE_DAY_USERS',
        'STATISTICS_CORE_DAY_MONEY_BOXES',
        'STATISTICS_ECOMMERCE_YEAR_BAGS',
        'STATISTICS_ECOMMERCE_YEAR_INCOME',
      ],
    },
    subRoleSectionSeccond: {
      title: 'common.rolesForm.subRoleSection.title.report.analitics',
      options: [
        'REPORT_INCOME_SUMMARY',
        'REPORT_FOOD_COST_BAGS',
        'REPORT_FOOD_COST',
        'REPORT_ORDERED_DIET',
        'REPORT_USAGE_DISCOUNT_CODES',
        'REPORT_MONEY_BOX_TOP_UP',
        'REPORT_INCOMING_SUBSCRIPTION',
        'REPORT_SENT_SMS',
        'REPORT_ACCOUNTANT_INCOME',
        'REPORT_ACCOUNTANT_NEW_ACCOUNTS',
        'REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS',
        'REPORT_MACRO_INGREDIENTS',
        'REPORT_EMPLOYEE',
        'ROLE_SHOW_REPORT_DISPOSAL',
      ],
    },
  },
  {
    name: 'common.roles.additionalSale',
    permissionTitle: [
      'MODULE_SHOP',
      'MODULE_ADDONS',
      'ADDON',
      // 'ADDITIONAL_OFFER',
      'SUB_BRAND',
      'OVERPRODUCTION',
    ],
  },
  {
    name: 'common.roles.cms',
    permissionTitle: ['PAGE'],
    subRoleSection: {
      title: 'common.rolesForm.subRoleSection.title.cms',
      options: [
        'CMS_DIET',
        'CMS_DISH',
        'CMS_ADDON',
        'CMS_ZONE',
        'CMS_MENU',
        'HOME_PAGE',
        'BLOG_POST',
      ],
    },
  },
  {
    name: 'common.roles.integration',
    permissionTitle: [],
    subRoleSection: {
      title: 'common.rolesForm.subRoleSection.title.integration',
      options: [
        'MODULE_GOOGLE_ANALYTICS',
        'MODULE_GOOGLE_TAG_MANAGER',
        'MODULE_HOT_JAR',
        'MODULE_FACEBOOK_PIXEL',
        'MODULE_MY_LEAD',
        'MODULE_SALES_MANAGO',
        'MODULE_GET_RESPONSE',
        'MODULE_IPRESSO',
        'MODULE_USER_COM',
        'MODULE_CLICK_ON_METRICS',
        'MODULE_POLLSTER_TRACK',
        'MODULE_BITRIX_24',
        'MODULE_TRUSTED_SHOPS',
        'MODULE_THULIUM',
        'MODULE_OMNICONVERT',
        'MODULE_SCAN_AND_SORT',
      ],
    },
  },
];
