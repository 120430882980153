import { Route, Router, Switch, useRouteMatch, useHistory } from 'react-router';

import withRole from 'components/Security/withRole';

import List from './List';
import Form from './Form';

const PickupPoints = ({ showRole, createRole, editRole }) => {
  const history = useHistory();
  const match = useRouteMatch();

  const ListSecured = withRole(showRole, List);
  const CreateForm = withRole(createRole, Form);
  const EditForm = withRole(editRole, Form);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={() => <ListSecured createRole={createRole} />}
        />
        <Route path={`${match.url}/add`} render={() => <CreateForm />} />
        <Route
          path={`${match.url}/edit/:id`}
          render={() => <EditForm isEdit={true} />}
        />
      </Switch>
    </Router>
  );
};

export default PickupPoints;
