import { openSweetToast } from '../../actions/App';
import FiltersModal from './FiltersModal';
import makeStyles from '@material-ui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import InfoIcon from 'assets/img/InfoIcon';

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    justifyContent: 'start',
    textAlign: 'left',
    cursor: 'pointer',
  },
});

const Filters = ({ filters, options }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const showFilter = () => {
    dispatch(
      openSweetToast({
        style: { display: 'block' },
        content: <FiltersModal filters={filters} options={options} />,
        showCancel: false,
      })
    );
  };

  return (
    <div className={classes.icon} onClick={showFilter}>
      <InfoIcon />
    </div>
  );
};

export default Filters;
