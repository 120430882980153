import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isGranted } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { Dialog } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import makeStyles from '@material-ui/styles/makeStyles';
import { useHistory } from 'react-router';
import classNames from 'classnames';

const columnConfig = [
  {
    title: 'ID',
    accessor: 'id',
    width: 100,
  },
  {
    title: 'columns.name',
    accessor: 'value',
  },
];

const useStyles = makeStyles(buttonsStyle);

const List = ({ createRole }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);

  return (
    <AdminTable title={t('pickupPoints.list')}>
      {isGranted(createRole) && (
        <FormControlButtons
          classes={classes}
          handleSubmit={() => history.push(`${window.location.pathname}/add`)}
          submitText={`${t('pickupPoints.addNew')} +`}
        />
      )}
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        paginationTop={true}
        paginationBottom={false}
        url="pick-up-points"
        columns={columnConfig}
        role="PICK_UP_POINT"
        remove={true}
      />
      <Dialog fullWidth={true} open={isConfirmModalOpened}>
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={t('common.shared.areYouSure')}
          onCancel={() => {
            setIsConfirmModalOpened(false);
          }}
          confirmBtnCssClass={classNames(classes.button, classes.success)}
          cancelBtnCssClass={classNames(classes.button, classes.danger)}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      </Dialog>
    </AdminTable>
  );
};

export default List;
