import { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import roles from 'helpers/roles';
import { isGranted } from 'helpers/helpers';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { fetchUserAddresses, removeAddress } from 'actions/Addresses';
import { compose } from 'redux';
import translateColumnConfig from './columnConfig';

import TableActions from 'components/DataTable/Actions';

import Form from './Form';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import SweetAlert from 'react-bootstrap-sweetalert';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import { useTranslation } from 'react-i18next';
import ModalButton from '../../../../components/CustomButtons/Button';
import classNames from 'classnames';

const useStyles = makeStyles({
  ...extendedFormsStyle,
  ...buttonsStyle,
  ...sweetAlertStyle,
  modalButton: {
    position: 'absolute',
    top: '0',
    right: '0',
    cursor: 'pointer',
  },
  sweetAlert: {
    display: 'block',
  },
});

const Addresses = ({
  userId: propUserId,
  match,
  fetchUserAddresses,
  removeAddress,
  addresses,
  useAddressesWithLessFields,
  noCancel,
  history,
  currentPathname,
  isChangeUrlLoader,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [alertCannotBeDeleted, setAlertCannotBeDeleted] = useState(false);
  const [removeAddressId, setRemoveAddressId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editAddress, setEditAddress] = useState([]);

  const userId = propUserId ? propUserId : match.params.id;

  useEffect(() => {
    fetchUserAddresses(userId, false);
  }, [userId]);

  const closeModal = () => {
    isChangeUrlLoader && history.replace({ pathname: currentPathname });
    setIsModalOpen(false);
    setEditAddress([]);
  };

  const newAddress = () => {
    isChangeUrlLoader &&
      history.replace({ pathname: `${currentPathname}/address/add` });

    setIsModalOpen(true);
  };

  const handleEdit = id => {
    isChangeUrlLoader &&
      history.replace({ pathname: `${currentPathname}/address/edit/${id}` });

    setIsModalOpen(true);
    setEditAddress(addresses.filter(address => id === address.id));
  };

  const reRenderModal = id => {
    handleEdit(id);
  };

  const renderDataTable = () =>
    addresses.map(address => {
      const [addressLine1 = '', addressLine2 = ''] =
        address?.street?.split('; ');

      return {
        id: address.id,
        city: address.city,
        ...(useAddressesWithLessFields
          ? { addressLine1, addressLine2 }
          : {
              street: address.street,
              buildNumber: address.buildNumber,
              placeNumber: address.placeNumber,
            }),

        postCode: address.postCode,
        actions: renderTableActions(address),
        addressUsage: address.addressUsage,
        zoneName: address?.zone?.name,
      };
    });

  const renderTableActions = address => {
    return (
      <TableActions
        handleEdit={
          isGranted(roles.ROLE_EDIT_ADDRESS)
            ? () => handleEdit(address.id)
            : false
        }
        handleRemove={
          isGranted(roles.ROLE_REMOVE_ADDRESS) && !address.containsActiveDiets
            ? address.isDefault
              ? () => setAlertCannotBeDeleted(true)
              : () => setRemoveAddressId(address.id)
            : false
        }
      />
    );
  };

  const columnConfig = translateColumnConfig(t, useAddressesWithLessFields);

  if (
    (isGranted(roles.ROLE_EDIT_ADDRESS) ||
      isGranted(roles.ROLE_REMOVE_ADDRESS)) &&
    columnConfig[columnConfig.length - 1].accessor !== 'actions'
  ) {
    columnConfig.push({
      Header: t('dataGrid.actions'),
      accessor: 'actions',
      width: 100,
      filterable: false,
    });
  }

  return (
    <>
      {isGranted(roles.ROLE_CREATE_ADDRESS) && (
        <FormControlButtons
          classes={classes}
          cancelPath={noCancel ? '' : '/admin/clients'}
          discardText={t('dataGrid.backToList')}
          handleSubmit={newAddress}
          submitText={t('dataGrid.addAddress')}
        />
      )}
      <ReactTable
        data={renderDataTable()}
        filterable={false}
        sortable={false}
        resizable={false}
        columns={columnConfig}
        minRows={10}
        showPaginationTop={true}
        showPaginationBottom={false}
        defaultPageSize={10}
        className="-striped -highlight"
        previousText={t('dataGrid.prevPage')}
        nextText={t('dataGrid.nextPage')}
        loadingText={t('dataGrid.loading')}
        noDataText={t('dataGrid.notFound')}
        pageText={t('dataGrid.page')}
        ofText={t('dataGrid.pageOf')}
        rowsText={t('dataGrid.results')}
      />
      <Dialog
        maxWidth="xl"
        fullWidth={true}
        open={isModalOpen}
        onClose={closeModal}
      >
        <DialogContent>
          <ModalButton
            className={classes.modalButton}
            color="transparent"
            justIcon={true}
            round={true}
            onClick={closeModal}
          >
            &times;
          </ModalButton>
          <Form
            userId={userId || null}
            closeModal={closeModal}
            editAddress={editAddress}
            reRenderModal={reRenderModal}
          />
        </DialogContent>
      </Dialog>
      <Dialog fullWidth={true} open={alertCannotBeDeleted}>
        <SweetAlert
          warning
          className={classes.sweetAlert}
          title={t('clients.deleteDefaultAddressWarn')}
          onConfirm={() => {
            setAlertCannotBeDeleted(false);
          }}
          confirmBtnCssClass={classNames(classes.button, classes.success)}
          confirmBtnText={t('common.shared.ok')}
        />
      </Dialog>
      <Dialog fullWidth={true} open={removeAddressId}>
        <SweetAlert
          warning
          className={classes.sweetAlert}
          title={t('clients.areYouSure')}
          onConfirm={async () => {
            await removeAddress(removeAddressId);
            fetchUserAddresses(userId);
            setAlertCannotBeDeleted(null);
          }}
          onCancel={() => {
            setRemoveAddressId(null);
          }}
          confirmBtnCssClass={classNames(classes.button, classes.success)}
          cancelBtnCssClass={classNames(classes.button, classes.danger)}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      </Dialog>
    </>
  );
};

const mapStateToProps = state => ({
  addresses: state.Addresses.addresses,
  useAddressesWithLessFields:
    state.Brands.modules?.ConfigClientPanel?.useAddressesWithLessFields ??
    false,
});

const mapDispatchToProps = dispatch => ({
  fetchUserAddresses: (id, pagination) =>
    dispatch(fetchUserAddresses(id, pagination)),
  removeAddress: id => dispatch(removeAddress(id)),
});

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Addresses);
