import { useState } from 'react';
import roles from 'helpers/roles';
import GridItem from 'components/Grid/GridItem';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import MIME_TYPES from './constants/mimeTypes';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(extendedFormsStyle);

const MoneyBoxTopUp = () => {
  const [groupClient, setGroupClient] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const title = t('reports.reportMBTopUp');

  return (
    <BrandDateSubbrandsReport
      useSubBrands={false}
      useDateRange={true}
      title={title}
      url="reports/money-box-top-up"
      mimeType={MIME_TYPES.xlsx}
      fileName={getReportFileName(title)}
      additionalParams={{ groupClient }}
      role={roles.ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES}
      archiveReportNames={['MONEY_BOX_HISTORY']}
    >
      <GridItem>
        <FormControlLabel
          control={
            <Checkbox
              checked={groupClient}
              onChange={e => {
                setGroupClient(prev => !prev);
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('reports.agrData')}
        />
      </GridItem>
    </BrandDateSubbrandsReport>
  );
};

export default MoneyBoxTopUp;
