import DICTIONARIES_TYPES from './dictionariesTypes.const';
import BooleanView from 'components/DataGrid/ValueViews/BooleanView';
import React from 'react';

const columnConfigs = ({ t, valueAccessor, type, antiPreferences }) => {
  const configs = {
    [DICTIONARIES_TYPES.REGULAR]: [
      {
        title: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        title: 'columns.name',
        accessor: valueAccessor,
      },
    ],
    [DICTIONARIES_TYPES.WITH_ANTI_PREFERENCES]: [
      {
        title: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        title: 'columns.name',
        accessor: valueAccessor,
      },
      // {
      //   title: 'columns.antiPreferences',
      //   name: 'antiPreferences',
      //   filterable: false,
      //   sortable: false,
      //   accessor: item => (
      //     <BooleanView value={antiPreferences.includes(item.id)} />
      //   ),
      // },
    ],
    [DICTIONARIES_TYPES.CONTAINERS]: [
      {
        title: 'container.id',
        accessor: 'id',
        name: 'id',
        filterable: false,
        sortable: true,
        width: 50,
      },
      {
        title: 'container.name',
        name: 'name',
        accessor: 'name',
        filterable: true,
        sortable: true,
      },
      {
        title: 'container.weight',
        name: 'weight',
        accessor: 'weight',
        filterable: false,
        sortable: true,
      },
      {
        title: 'container.volume',
        name: 'volume',
        accessor: 'volume',
        filterable: false,
        sortable: true,
      },
      {
        title: 'container.cost',
        name: 'cost',
        accessor: 'cost',
        filterable: false,
        sortable: true,
      },
    ],
    [DICTIONARIES_TYPES.WITH_POSITION]: [
      {
        title: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        title: 'columns.name',
        accessor: 'value',
      },
      {
        title: 'columns.position',
        accessor: 'position',
      },
    ],
    [DICTIONARIES_TYPES.WITH_POSITION_AND_ANTI_PREFERENCES]: [
      {
        title: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        title: 'columns.name',
        accessor: 'value',
      },
      {
        title: 'columns.position',
        accessor: 'position',
      },
      {
        title: 'columns.antiPreferences',
        name: 'antiPreferences',
        filterable: false,
        sortable: false,
        accessor: item => (
          <BooleanView value={antiPreferences.includes(item.id)} />
        ),
      },
    ],
    [DICTIONARIES_TYPES.CLIENT_TAGS]: [
      {
        title: 'ID',
        accessor: 'id',
        width: 100,
      },
      {
        title: 'columns.name',
        accessor: 'value',
      },
      {
        title: 'columns.position',
        accessor: 'position',
      },
      {
        title: 'columns.icon',
        name: 'icon',
        accessor: row => {
          if (!row?.icon) return;

          const imageSrc = row?.icon?.contentUrl
            ? process.env.REACT_APP_STATIC_FILES_CDN + row?.icon?.contentUrl
            : null;

          return (
            <img
              src={imageSrc}
              alt={row?.icon?.id}
              style={{ width: '30px', height: '30px' }}
            />
          );
        },
      },
      {
        title: 'columns.icon_visible',
        name: 'iconVisible',
        accessor: row => {
          if (![0, 1, 2].includes(row?.iconVisible)) return '';

          const iconVisibleLabels = [
            t('form.field.displayMode.onlyName', 'Sama nazwa'),
            t('form.field.displayMode.onlyIcon', 'Sama ikonka'),
            t('form.field.displayMode.nameAndIcon', 'Nazwa i ikonka'),
          ];

          return iconVisibleLabels[row?.iconVisible];
        },
      },
    ],
    [DICTIONARIES_TYPES.BAG_CONTAINERS]: [
      {
        title: 'bagContainer.id',
        accessor: 'id',
        name: 'id',
        filterable: false,
        sortable: true,
        width: 50,
      },
      {
        title: 'bagContainer.name',
        name: 'name',
        accessor: 'name',
        filterable: true,
        sortable: true,
      },
      {
        title: 'bagContainer.clientCost',
        name: 'clientCost',
        accessor: 'clientCost',
        filterable: false,
        sortable: true,
      },
      {
        title: 'bagContainer.clientVat',
        name: 'clientVat',
        accessor: 'clientVat',
        filterable: false,
        sortable: true,
      },
      {
        title: 'bagContainer.brandCost',
        name: 'brandCost',
        accessor: 'brandCost',
        filterable: false,
        sortable: true,
      },
      {
        title: 'bagContainer.brandVat',
        name: 'brandVat',
        accessor: 'brandVat',
        filterable: false,
        sortable: true,
      },
      {
        title: 'bagContainer.volume',
        name: 'volume',
        accessor: 'volume',
        filterable: false,
        sortable: true,
      },
      {
        title: 'bagContainer.createdAt',
        name: 'createdAt',
        accessor: 'createdAt',
        filterable: false,
        sortable: true,
      },
      {
        title: 'bagContainer.updatedAt',
        name: 'updatedAt',
        accessor: 'updatedAt',
        filterable: false,
        sortable: true,
      },
    ],
  };

  return configs[type];
};

export default columnConfigs;
