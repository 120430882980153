import { AddAPhoto, Delete } from '@material-ui/icons';
import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_DISH_PHOTO_GALLERY } from 'helpers/roles';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dialog, withStyles } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle';

export const CustomButton = () => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        width: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid RGB(0,172,194)',
        borderRadius: '8px',
        color: 'RGB(0,172,194)',
        padding: '5px 0',
        cursor: 'pointer',
      }}
    >
      <div>
        <AddAPhoto />
      </div>
      <div style={{ marginLeft: '10px' }}>
        {t('common.dishesPhoto.dishes.customButton.add', 'Dodaj')}
      </div>
    </div>
  );
};

export const CustomButtonAddIcon = ({ previewUrl = '', handleClick }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        color: !isGranted(ROLE_EDIT_DISH_PHOTO_GALLERY)
          ? 'RGBA(0,172,194,0.2)'
          : 'RGB(0,172,194)',
        margin: '0 20px 0 20px',
        display: previewUrl === null ? 'none' : 'block',
      }}
      onClick={isGranted(ROLE_EDIT_DISH_PHOTO_GALLERY) && handleClick}
    >
      {<AddAPhoto />}
    </div>
  );
};

export const CustomButtonRemoveIcon = ({ handleClick, classes }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Dialog fullWidth={true} open={isOpen}>
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={t('common.shared.areYouSure')}
          onConfirm={() => handleClick()}
          onCancel={() => setIsOpen(false)}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      </Dialog>

      <div
        style={{
          cursor: 'pointer',
          color: !isGranted(ROLE_EDIT_DISH_PHOTO_GALLERY)
            ? 'RGBA(244,67,54,0.2)'
            : 'RGB(244,67,54)',
        }}
        onClick={() =>
          isGranted(ROLE_EDIT_DISH_PHOTO_GALLERY) && setIsOpen(true)
        }
      >
        <Delete />
      </div>
    </>
  );
};

const StyledCustomButtonRemoveIcon = withStyles(buttonStyle)(
  CustomButtonRemoveIcon
);
export { StyledCustomButtonRemoveIcon };
