import { get } from 'helpers/apiHelpers';
import types from 'helpers/constants';

export const fetchSubBrands = (brands = []) => {
  return dispatch => {
    return get('/sub-brands', {
      pagination: false,
      selectInBrands: brands,
    }).then(response => {
      dispatch({
        type: types.FETCH_SUBBRANDS,
        payload: {
          subBrands: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};
