import { toast } from 'react-toastify';
import Moment from 'moment';
import { Check, Clear } from '@material-ui/icons';

import { put } from 'helpers/apiHelpers';
import { currencyToSymbol } from 'utils/currencies';
import YesNoFilter from 'components/Grid/Filter/YesNo';
import YesNoCell from 'components/Grid/YesNoCell';
import DiscountFilterWithPercentage from 'views/PromoCodes/DiscountFilterWithPercentage';

const columnConfig = (diets, t) => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    filterable: false,
    sortable: true,
    width: 100,
  },
  {
    title: 'discountCodes.code',
    accessor: 'code',
    name: 'code',
    filterable: true,
    sortable: false,
  },
  {
    title: 'discountCodes.valueAndType',
    accessor: (row, { multinational: { currency } }) =>
      row.isPercentage
        ? `${row.discount}%`
        : `${row.discount} ${currencyToSymbol(currency)}`,
    name: 'discount',
    Filter: ({ onChange, filter }) => (
      <DiscountFilterWithPercentage
        onFilterChange={onChange}
        filterValue={filter?.value}
      />
    ),
    sortable: true,
    width: 210,
  },
  {
    title: 'discountCodes.remUses',
    accessor: row =>
      row.limit === null ? t('discountCodes.noLimit') : row.limit,
    name: 'limit',
    filterable: false,
    sortable: false,
    width: 100,
  },
  {
    title: 'discountCodes.usesLimitPerClient',
    accessor: row =>
      row.limitPerUser === null ? t('discountCodes.noLimit') : row.limitPerUser,
    name: 'limitPerUser',
    filterable: false,
    sortable: false,
    width: 150,
  },
  {
    title: 'discountCodes.usesLimitPerSubscription',
    accessor: row =>
      row.limitPerSubscription === null
        ? t('discountCodes.noLimit')
        : row.limitPerSubscription,
    name: 'limitPerSubscription',
    filterable: false,
    sortable: false,
    width: 150,
  },
  {
    title: 'discountCodes.validFrom',
    name: 'validFrom',
    accessor: row =>
      row.validFrom === null
        ? '-'
        : `${new Moment(row.validFrom).format('DD.MM.YYYY HH:mm')}`,
    filterable: false,
    sortable: true,
    width: 125,
  },
  {
    title: 'discountCodes.validTo',
    accessor: row =>
      row.validTo === null
        ? '-'
        : `${new Moment(row.validTo).format('DD.MM.YYYY HH:mm')}`,
    name: 'validTo',
    filterable: false,
    sortable: true,
    width: 125,
  },
  {
    title: 'discountCodes.diets',
    accessor: row => {
      const filteredDiets = diets.filter(
        diet => row.diets && row.diets.includes(diet['@id'])
      );

      return filteredDiets.map(diet => diet.name).join(', ');
    },
    name: 'diets',
    filterable: false,
    sortable: false,
  },
  {
    title: 'discountCodes.applyToAddons',
    accessor: row =>
      row.applyToAddons ? (
        <Check style={{ color: '#46ad58' }} />
      ) : (
        <Clear style={{ color: '#f4928d' }} />
      ),
    name: 'applyToAddons',
    filterable: false,
    sortable: false,
  },
  {
    title: 'discountCodes.desc',
    accessor: 'description',
    name: 'description',
    filterable: false,
    sortable: false,
  },
  {
    title: 'discountCodes.onlyForAdmin',
    accessor: row =>
      row.onlyAdminCanUse ? t('discountCodes.yes') : t('discountCodes.no'),
    name: 'onlyAdminCanUse',
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    width: 100,
  },
  {
    title: 'discountCodes.disableDietDiscount',
    accessor: row =>
      row.disableDietDiscount ? t('discountCodes.yes') : t('discountCodes.no'),
    name: 'disableDietDiscount',
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    width: 100,
  },
  {
    title: 'discountCodes.status',
    accessor: 'active',
    name: 'active',
    sortable: false,
    Cell: ({ row }) => (
      <YesNoCell
        value={row.active}
        trueLabel={t('form.field.active', 'Aktywny')}
        falseLabel={t('form.field.inactive', 'Nieaktywny')}
        onChange={e =>
          put(`discount-codes/${row.id}`, {
            active: e.target.value,
          }).then(() => {
            toast.error(`${t('promoCodes.changedCodeStatus')} ${row.code}`);
          })
        }
      />
    ),
    Filter: ({ onChange, filter }) => (
      <YesNoFilter
        onChange={onChange}
        trueLabel={t('discountCodes.active')}
        falseLabel={t('discountCodes.inactive')}
        value={`${filter?.value}`}
      />
    ),
    width: 150,
  },
  {
    title: 'common.mainMenu.discountCodes.archive',
    accessor: 'archived',
    name: 'archived',
    sortable: false,
    Cell: ({ row }) => (
      <YesNoCell
        value={row.archived}
        onChange={() => {
          put(`discount-codes/${row.id}`, {
            archived: !row.archived,
          }).then(data => {
            toast.info(
              data?.archived
                ? t('discountCodes.info9')
                : t('discountCodes.info10')
            );
          });
        }}
      />
    ),
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
    width: 100,
  },
];

export default columnConfig;
