import PropTypes from 'prop-types';
import React from 'react';
import axios from 'helpers/gastro';
import Cookies from 'cookies-js';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import roles from 'helpers/roles';
import { combineStyles, isGranted } from 'helpers/helpers';

import { fetchZoneCategories } from 'actions/ZoneCategories';
import { fetchProductionTags } from 'actions/Dictionary';
import { fetchSubBrands } from 'actions/SubBrands';

//components
import { SelectInput } from 'components';
import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import Archive from 'components/Archive/Archive';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import SelectAll from 'components/SelectAll';
import GridContainer from 'components/Grid/GridContainer';
import AccessDenied from 'components/Security/AccessDenied';
import ReportConfigurator from 'components/Report/ReportConfigurator';

import { AddCircle } from '@material-ui/icons';
import { Dialog, DialogContent, Tooltip } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';

//styles
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import CombinedReportRow from './CombinedReportRow';
import Info from '@material-ui/icons/Info';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from '../DownloadReportButtons/DownloadReportButtons';
import MIME_TYPES_DATA from '../constants/mimeTypesData';
import { toast } from 'react-toastify';

const defaultRow = () => ({
  _key: uuidv4(),
  brands: [],
  multiplier: 1,
  subBrands: [],
  dateFrom: '',
  dateTo: '',
  zoneCategories: [],
  includeSubscriptions: false,
  recipeTags: [],
});

class CombinedReport extends React.Component {
  state = {
    isGenerating: false,
    initialized: false,
    parameters: [defaultRow()],
    recipeTags: [],
    availableWarhouses: [],
  };

  componentDidMount = async () => {
    const brandsIris = this.props.brands.map(brand => brand['@id']);
    if (
      this.props.useZoneCategories &&
      isGranted(roles.ROLE_SHOW_ZONE_CATEGORY)
    ) {
      await this.props.fetchZoneCategories(brandsIris);
    }
    if (this.props.useRecipeTags) {
      await this.props.fetchProductionTags();
    }
    if (this.props.useWarehouse) {
      try {
        let newAvailableWarhouses = await axios.get(`/fakturownia/warehouses`);

        if (Array.isArray(newAvailableWarhouses?.data)) {
          await this.setState(prevState => ({
            ...prevState,
            warehouse: !isEmpty(newAvailableWarhouses?.data)
              ? newAvailableWarhouses?.data[0]
              : null,
            availableWarhouses: newAvailableWarhouses?.data,
          }));
        }
      } catch (e) {
        console.log(e);
      }
    }

    await this.props.fetchSubBrands(brandsIris);

    if (Cookies.get('combined_report_v2')) {
      await this.setState(prevState => ({
        ...prevState,
        ...JSON.parse(Cookies.get('combined_report_v2')),
      }));
    }
    await this.setState(prevState => ({
      ...prevState,
      initialized: true,
    }));
  };

  addNextRow = () =>
    this.setState(prevState => ({
      ...prevState,
      parameters: [...prevState.parameters, defaultRow()],
    }));

  onRowRemoved = row => {
    this.setState(prevState => ({
      ...prevState,
      parameters: prevState.parameters.filter(
        parameter => parameter._key !== row._key
      ),
    }));
  };

  onRowUpdated = row => {
    const parameters = this.state.parameters;

    let rowIndex = parameters.findIndex(
      parameter => parameter._key === row._key
    );

    parameters[rowIndex] = { ...parameters[rowIndex], ...row };

    this.setState(prevState => ({
      ...prevState,
      parameters: parameters,
    }));
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validate = row => {
    if (!this.validateDate(row)) {
      toast.error(this.props.t('reports.sDate'));
      return false;
    }
    if (!this.validateBrands(row)) {
      toast.error(this.props.t('reports.selectBrand'));
      return false;
    }

    return true;
  };

  validateDate = row => {
    if (this.props.validateDatePicker) {
      return this.props.useDateRange
        ? row.dateFrom && row.dateTo
        : row.dateFrom;
    }

    return true;
  };

  validateBrands = row => {
    if (this.props.validateBrands) {
      return row.brands.length !== 0;
    }

    return true;
  };

  handleWarehouse = async (ev, selectedValue) => {
    await this.setState({
      warehouse: selectedValue,
    });
  };

  handleRecipeTags = async selected => {
    await this.setState({
      recipeTags: selected.filter(el => typeof el != 'undefined'),
    });
  };

  filterRecipeTags = recipeTags => {
    return recipeTags.filter(val => val).map(val => val.value);
  };

  handleGenerate = (mimeType, lang) => {
    if (this.state.parameters.some(parameter => !this.validate(parameter))) {
      return;
    }
    Cookies.set('combined_report_v2', JSON.stringify(this.state));
    this.setState({ isGenerating: true });

    const params = this.getParams();

    const actionsConfig = {
      responseType: 'blob',
      headers: { accept: mimeType },
      forcedLanguage: lang,
    };

    const action = this.props.usePost
      ? axios.post(this.props.url, params, actionsConfig)
      : axios.get(this.props.url, {
          ...actionsConfig,
          params: params,
        });

    action
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.props.fileName(this.state)}.${
                MIME_TYPES_DATA[mimeType].extension
              }`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(this.props.t('reports.noAccess'));
          }
        },
        error => {
          if (typeof error.response !== 'undefined') {
            toast.error(
              `${this.props.t('reports.cannotGenerate')} ${
                error.response.data['hydra:description']
              }`
            );
          } else {
            toast.error(`${this.props.t('reports.cannotGenerate')} ${error}`);
          }
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  getParams = () => {
    const parameters = this.state.parameters.map(parameter => {
      let recipeTags = [];

      if (this.props.useRecipeTags) {
        recipeTags = this.filterRecipeTags(
          this.props.singleSelectRecipeTags
            ? this.state.recipeTags
            : parameter.recipeTags
        );
      }

      let mappedParameter = {
        ...parameter,
        brands: parameter.brands.filter(val => val).map(val => val.value),
        subBrands: parameter.subBrands.filter(val => val).map(val => val.value),
        ...(this.props.useZoneCategories && {
          zoneCategories: parameter.zoneCategories
            .filter(val => val)
            .map(val => val.value),
        }),
        recipeTags: recipeTags,
      };

      return mappedParameter;
    });

    let additionalParams = {};

    if (typeof this.props.additionalParams === 'object') {
      additionalParams = this.props.additionalParams;
    }

    if (this.props.useRecipeTags && this.props.singleSelectRecipeTags) {
      additionalParams.recipeTags = this.filterRecipeTags(
        this.state.recipeTags
      );
    }

    if (this.props.useWarehouse && this.props.singleSelectWarehouse) {
      additionalParams.warehouse = this.state.warehouse;
    }

    const params = {
      parameters: parameters,
      ...additionalParams,
    };
    return params;
  };

  render() {
    const { classes, title, company, reportConfiguration } = this.props;
    const companyId = parseInt(company.split('/').pop());
    const reportKey = reportConfiguration?.report || false;
    const fields = reportConfiguration?.fields;
    if (!this.state.initialized) {
      return null;
    }

    return isGranted(this.props.role) ? (
      <>
        <CardWrapper
          title={
            <>
              {title}
              {reportKey && (
                <ReportConfigurator
                  companyId={companyId}
                  brandId={this.props.brandId}
                  report={reportKey}
                  fields={fields}
                />
              )}
            </>
          }
          style={{ marginTop: '20px' }}
        >
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{this.props.t('reports.generate')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardBody>
            {this.state.parameters.map((el, index) => (
              <CombinedReportRow
                key={el._key}
                _key={el._key}
                onRowRemoved={this.onRowRemoved}
                onRowUpdated={this.onRowUpdated}
                canBeRemoved={index !== 0}
                brands={el.brands}
                subBrands={el.subBrands}
                zoneCategories={el.zoneCategories}
                recipeTags={el.recipeTags}
                dateFrom={el.dateFrom}
                dateTo={el.dateTo}
                multiplier={el.multiplier}
                includeSubscriptions={el.includeSubscriptions}
                useDateRange={this.props.useDateRange}
                useSubBrands={this.props.useSubBrands}
                useZoneCategories={this.props.useZoneCategories}
                useRecipeTags={
                  this.props.useRecipeTags && !this.props.singleSelectRecipeTags
                }
                showMultiplier={this.props.showMultiplier}
                showIncludeSubscriptions={this.props.showIncludeSubscriptions}
              />
            ))}
            <GridContainer justify="flex-end">
              {this.props.singleSelectWarehouse && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '2px' }}
                  >
                    {this.props.t('reports.selectWarehouse')}
                  </FormLabel>
                  <SelectInput
                    style={{ padding: 0 }}
                    noGrid
                    classes={classes}
                    mapBy="name"
                    trackBy="id"
                    name={'warehouse'}
                    value={this.state.warehouse}
                    options={this.state.availableWarhouses}
                    handleChange={this.handleWarehouse}
                  />
                </GridItem>
              )}
              {this.props.singleSelectRecipeTags && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '2px' }}
                  >
                    {this.props.labelSelectTags
                      ? this.props.labelSelectTags
                      : this.props.t('reports.selectRecipeTags')}
                  </FormLabel>
                  <Tooltip
                    style={{
                      color: 'grey',
                      marginLeft: '5px',
                      marginBottom: '-4px',
                    }}
                    title={
                      <h4>
                        {[
                          'SHOPPING_LIST',
                          'CHECK_LIST_RECIPES',
                          'RECIPES_CARD',
                        ].some(el => el === this.props.name)
                          ? this.props.t('reports.selectTags.recipe.tooltip')
                          : ['CHECK_LIST_DISHES', 'DISHES_CARD'].some(
                              el => el === this.props.name
                            )
                          ? this.props.t('reports.selectTags.dish.tooltip')
                          : null}
                      </h4>
                    }
                    placement="right"
                  >
                    <Info fontSize="small" />
                  </Tooltip>
                  <SelectAll
                    className="input-select--production-tags"
                    options={this.props.recipeTagsOptions}
                    trackBy={'@id'}
                    mapBy={'value'}
                    optionSelected={this.state.recipeTags}
                    handleChange={this.handleRecipeTags}
                  />
                </GridItem>
              )}
            </GridContainer>

            {this.props.children}
            <GridContainer justify={'space-between'}>
              <GridItem>
                <Button
                  onClick={() => this.addNextRow()}
                  disabled={this.state.isGenerating}
                  color={'default'}
                  round
                  justIcon
                >
                  <AddCircle />
                </Button>
              </GridItem>
              <div style={{ display: 'flex' }}>
                {(this.props.customActions ?? []).map(({ onClick, label }) => (
                  <GridItem key={label}>
                    <Button
                      onClick={async () => {
                        this.setState({ isGenerating: true });
                        try {
                          const params = this.getParams();
                          await onClick(params);
                          this.setState({ isGenerating: false });
                        } catch (e) {
                          this.setState({ isGenerating: false });
                        }
                      }}
                      disabled={this.state.isGenerating}
                      color={'success'}
                      round
                    >
                      {label}
                    </Button>
                  </GridItem>
                ))}
                <GridItem>
                  <DownloadReportButtons
                    isSalesMarketLangViewEnabled={
                      this.props.isSalesMarketLangViewEnabled
                    }
                    mimeTypes={this.props.mimeTypes}
                    handleGenerate={this.handleGenerate}
                    areButtonsDisabled={this.state.isGenerating}
                  />
                </GridItem>
              </div>
            </GridContainer>
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive
              options={{
                brands: this.props.brands,
                subBrands: this.props.subBrandsOptions,
                zoneCategories: this.props.zoneCategories,
                recipeTags: this.props.recipeTagsOptions,
              }}
              type="REPORT"
              reportName={this.props.archiveReportNames}
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brandId: state.Brands.brand.id,
  brands: state.Auth.user.brands,
  company: state.Auth.user.company,
  zoneCategories: state.ZoneCategories.zoneCategories,
  subBrandsOptions: state.SubBrands.subBrands,
  recipeTagsOptions: state.Dictionary.productionTags,
});

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: brands => dispatch(fetchZoneCategories(brands)),
  fetchProductionTags: () => dispatch(fetchProductionTags()),
  fetchSubBrands: brands => dispatch(fetchSubBrands(brands)),
});

CombinedReport.defaultProps = {
  isSalesMarketLangViewEnabled: false,
};

CombinedReport.propTypes = {
  brands: PropTypes.array,
  zoneCategories: PropTypes.array,
  classes: PropTypes.any,
  archiveReportNames: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.func.isRequired,
  showMultiplier: PropTypes.bool,
  validateBrands: PropTypes.bool,
  validateDatePicker: PropTypes.bool,
  usePost: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useSubBrands: PropTypes.bool,
  useZoneCategories: PropTypes.bool,
  useRecipeTags: PropTypes.bool,
  mimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  isSalesMarketLangViewEnabled: PropTypes.bool,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(CombinedReport);
