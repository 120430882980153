import { Component } from 'react';

import { fetchDrivers } from 'actions/Drivers';
import { fetchZones } from 'actions/Zones';
import { fetchDeliveryTypes } from 'actions/DeliveryTypes';
import { connect } from 'react-redux';

import Datetime from 'react-datetime';
import moment from 'moment';
import { isDatepickerValidDay } from 'helpers/dateHelpers';

import SelectAll from 'components/SelectAll';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import SelectInput from '../../components/FormSelect/SelectInput';
import axios from '../../helpers/gastro';
import { Dialog, DialogContent } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccessDenied from 'components/Security/AccessDenied';
import roles, { ROLE_SHOW_REPORT_FOR_DRIVER } from '../../helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ReportConfigurator from '../../components/Report/ReportConfigurator';
import Archive from '../../components/Archive/Archive';
import { fetchZoneCategories } from '../../actions/ZoneCategories';
import CardWrapper from 'components/Card/CardWrapper';
import DownloadReportButtons from './DownloadReportButtons/DownloadReportButtons';
import MIME_TYPES from './constants/mimeTypes';
import MIME_TYPES_DATA from './constants/mimeTypesData';
import { toast } from 'react-toastify';
import { apiErrorToast } from 'utils/apiError';

const customSelectStyles = {
  height: '32px',
};

class ForDriver extends Component {
  state = {
    selectedBrands: [],
    selectedDrivers: [],
    selectedZones: [],
    selectedDeliveryType: '',
    selectedDeliveryPoint: false,
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
    isLoading: false,
    selectedZoneCategories: [],
    filteredZones: null,
  };

  componentDidMount = () => {
    this.setState({
      isLoading: true,
    });

    const loader = [
      this.props.fetchDrivers(),
      this.props.fetchZones(),
      this.props.fetchDeliveryTypes(),
    ];

    if (isGranted(roles.ROLE_SHOW_ZONE_CATEGORY)) {
      loader.push(this.props.fetchZoneCategories());
    }
    Promise.all(loader).then(() => {
      this.setState({
        isLoading: false,
      });
    });
  };

  filterZonesBasedOnChosenCategory = async () => {
    const zoneCategoriesIds = [];
    this.state.selectedZoneCategories.forEach(zoneCategory => {
      if (!isNaN(zoneCategory.value)) {
        zoneCategoriesIds.push(zoneCategory.value);
      }
    });

    const filteredZones = this.props.zones.filter(zone =>
      zoneCategoriesIds.includes(zone?.category?.id)
    );

    await this.setState({ filteredZones });
    const selected = filteredZones.map(zone => ({
      label: zone.name,
      value: zone['@id'],
    }));
    this.setState({ selectedZones: selected });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.selectedZoneCategories.length !==
      this.state.selectedZoneCategories.length
    ) {
      this.filterZonesBasedOnChosenCategory();
    }
  }

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.state.dateFrom && this.state.dateTo;
  };

  getReportFileName = extension => {
    const title = this.props.t(
      'common.mainMenu.reportsForDriver',
      'Dla kierowcy'
    );

    return `${title.replaceAll(' ', '_')}-${this.state.dateFrom}-${
      this.state.dateTo
    }_(${moment().format('YYYY-MM-DD_HH.mm')}).${extension}`;
  };

  handleGenerate = (mimeType, lang) => {
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return toast.error(this.props.t('reports.sDate'));
    }

    const params = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      zones: this.state.selectedZones
        .map(zone => zone.value)
        .filter(val => val !== '*'),
      'delivery-point': this.state.selectedDeliveryPoint,
      drivers: this.state.selectedDrivers
        .map(driver => driver.value)
        .filter(val => val !== '*'),
      pagination: false,
    };

    axios
      .get('reports/delivery', {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
        forcedLanguage: lang,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            this.getReportFileName(MIME_TYPES_DATA[mimeType].extension)
          );
          document.body.appendChild(link);
          link.click();
          this.setState({ isGenerating: false });
        },
        error => {
          apiErrorToast(this.props.t('reports.cannotGenerate'), error);
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  render() {
    const { classes, company } = this.props;
    const companyId = parseInt(company.split('/').pop());

    return isGranted(ROLE_SHOW_REPORT_FOR_DRIVER) ? (
      <>
        <CardWrapper
          title={
            <>
              {this.props.t('reports.reportForDriver')}
              <ReportConfigurator
                companyId={companyId}
                report="Delivery"
                fields={[
                  {
                    size: 12,
                    field: 'extendedUserInfo',
                    inputType: 'select',
                    inputProps: {
                      multiple: false,
                      options: [
                        {
                          value: true,
                          label: this.props.t(
                            `configuration.reports.report.Delivery.extendedUserInfo.yes`
                          ),
                        },
                        {
                          value: false,
                          label: this.props.t(
                            `configuration.reports.report.Delivery.extendedUserInfo.no`
                          ),
                        },
                      ],
                    },
                  },
                  {
                    size: 12,
                    field: 'addColumnWithV2Code',
                    inputType: 'select',
                    inputProps: {
                      multiple: false,
                      options: [
                        {
                          value: true,
                          label: this.props.t(
                            `configuration.reports.report.Delivery.addColumnWithV2Code.yes`
                          ),
                        },
                        {
                          value: false,
                          label: this.props.t(
                            `configuration.reports.report.Delivery.addColumnWithV2Code.no`
                          ),
                        },
                      ],
                    },
                  },
                  {
                    size: 12,
                    field: 'showUserEmail',
                    inputType: 'select',
                    inputProps: {
                      multiple: false,
                      options: [
                        { value: true, label: this.props.t(`common.yes`) },
                        { value: false, label: this.props.t(`common.no`) },
                      ],
                    },
                  },
                  {
                    size: 12,
                    field: 'mode',
                    inputType: 'select',
                    inputProps: {
                      multiple: false,
                      options: ['BAG', 'POINT'].map(option => ({
                        value: option,
                        label: this.props.t(
                          `configuration.reports.report.Delivery.mode.${option}`
                        ),
                      })),
                    },
                  },
                ]}
              />
            </>
          }
          style={{ marginTop: '20px' }}
        >
          <Dialog open={this.state.isLoading}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>Ładowanie</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>
                  {this.props.t('reports.generate', 'Trwa generowanie raportu')}
                </h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>
          <CardBody>
            <GridContainer>
              <GridItem sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('reports.forDriver.dateFrom')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-from"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateFrom)}
                    onChange={ev => {
                      this.setState({ dateFrom: ev.format('YYYY-MM-DD') });
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={3}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('reports.forDriver.dateTo')} *
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    className="input-datetime--date-to"
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateTo)}
                    onChange={ev => {
                      this.setState({ dateTo: ev.format('YYYY-MM-DD') });
                    }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {this.props.t('reports.selectDeliveryType')}
                </FormLabel>
                <SelectInput
                  className="input-select--delivery-type"
                  noGrid
                  classes={classes}
                  customStyle={customSelectStyles}
                  options={[
                    {
                      label: this.props.t('reports.doorDelivery'),
                      value: false,
                    },
                    {
                      label: this.props.t('reports.pickupPointDelivery'),
                      value: true,
                    },
                  ]}
                  trackBy={['value']}
                  mapBy="label"
                  value={this.state.selectedDeliveryPoint}
                  handleChange={selected => {
                    this.setState({
                      selectedDeliveryPoint: selected.target.value,
                      selectedZones: [],
                      selectedDrivers: [],
                    });
                  }}
                />
              </GridItem>
              {!this.state.selectedDeliveryPoint && (
                <>
                  <GridItem sm={6}>
                    <FormLabel className={classes.labelHorizontal}>
                      {this.props.t('reports.selectZoneCategories')}
                    </FormLabel>
                    <SelectAll
                      className="input-select--zone-category"
                      options={this.props.zoneCategories}
                      trackBy={'id'}
                      mapBy={'name'}
                      optionSelected={this.state.selectedZoneCategories}
                      handleChange={selected => {
                        this.setState({
                          selectedZoneCategories: selected,
                          selectedZones: [],
                        });
                      }}
                    />
                  </GridItem>
                  {this.state.filteredZones &&
                  this.state.filteredZones.length ? (
                    <GridItem sm={6}>
                      <FormLabel className={classes.labelHorizontal}>
                        {this.props.t('reports.selectZones')}
                      </FormLabel>
                      <SelectAll
                        className="input-select--filtered-zone"
                        options={this.state.filteredZones}
                        trackBy={['@id']}
                        mapBy={'name'}
                        optionSelected={this.state.selectedZones}
                        handleChange={selected => {
                          this.setState({
                            selectedZones: selected,
                          });
                        }}
                      />
                    </GridItem>
                  ) : null}
                </>
              )}
            </GridContainer>
            <GridContainer>
              {!this.state.selectedDeliveryPoint && (
                <GridItem sm={6}>
                  <FormLabel className={classes.labelHorizontal}>
                    {this.props.t('reports.selectDrivers')}
                  </FormLabel>
                  <SelectAll
                    className="input-select--driver"
                    options={this.props.drivers}
                    trackBy={['@id']}
                    mapBy={'name'}
                    selectAllLabel={'Wszyscy'}
                    optionSelected={this.state.selectedDrivers}
                    handleChange={selected => {
                      this.setState({
                        selectedDrivers: selected,
                      });
                    }}
                  />
                </GridItem>
              )}
            </GridContainer>
            <GridContainer justify="flex-end">
              <GridItem>
                <DownloadReportButtons
                  isSalesMarketLangViewEnabled={true}
                  mimeTypes={[MIME_TYPES.pdf, MIME_TYPES.xlsx]}
                  handleGenerate={this.handleGenerate}
                  areButtonsDisabled={this.state.isGenerating}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive
              type={['REPORT']}
              reportName={['DELIVERY']}
              options={{
                drivers: this.props.drivers,
                zones: this.props.zones,
              }}
            />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  company: state.Auth.user.company,
  selectedBrand: state.Auth.user.selectedBrand,
  zones: state.Zones.zones,
  zoneCategories: state.ZoneCategories.zoneCategories,
  drivers: state.Drivers.drivers,
  deliveryTypes: state.DeliveryTypes.deliveryTypes,
});

const mapDispatchToProps = dispatch => ({
  fetchDrivers: () => dispatch(fetchDrivers()),
  fetchZones: () => dispatch(fetchZones()),
  fetchDeliveryTypes: () => dispatch(fetchDeliveryTypes()),
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(ForDriver);
