import React from 'react';

import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button.jsx';

const DialogImages = ({
  choicesImage,
  backgroundArray,
  setBackground,
  setConfigClientPanelModule,
  setIsOpen,
  isOpen,
  t,
  mode,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <DialogContent>
        <GridContainer>
          {backgroundArray?.map((image, index) => {
            return (
              <GridItem
                key={index}
                xs={3}
                onClick={() => {
                  setConfigClientPanelModule(mode, image);
                  setBackground({
                    image: image,
                    index: index,
                  });
                }}
              >
                <img
                  style={{
                    width: '200px',
                    height: '200px',
                    border:
                      choicesImage.index === index
                        ? '5px solid RGB(95,176,87)'
                        : '5px solid RGB(217,217,217)',
                  }}
                  src={
                    process.env.REACT_APP_STATIC_FILES_CDN + image.contentUrl
                  }
                  alt="dynamicBackground"
                />
              </GridItem>
            );
          })}
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {t('form.close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogImages;
