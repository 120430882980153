import React from 'react';
import CustomInput from 'components/CustomInput/CustomInput.jsx';

const FormTextInputNoGrid = ({
  classes,
  success,
  error,
  helpText,
  handleChange,
  multiline,
  rows,
  rowsMax,
  name,
  value,
  type,
  disabled,
  maxLength,
  noAutoComplete,
  overrideClasses,
  ...props
}) => {
  return (
    <CustomInput
      id={name}
      success={success}
      error={error}
      helpText={helpText}
      required={true}
      maxLength={maxLength}
      formControlProps={{
        fullWidth: true,
      }}
      overrideClasses={overrideClasses}
      inputProps={{
        multiline: multiline,
        onChange: event => handleChange(event),
        value: value,
        type: type ? type : 'text',
        rows: rows,
        rowsMax: rowsMax,
        name: name,
        disabled: disabled ? disabled : false,
        autoComplete: noAutoComplete ? 'new-password' : 'on',
        ...props,
      }}
    />
  );
};

export default FormTextInputNoGrid;
