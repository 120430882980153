import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';

import GridItem from 'components/Grid/GridItem.jsx';
import ImageUpload from 'components/CustomUpload/ImageUpload.jsx';
import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import gastro from 'helpers/gastro';

const FormImageUpload = ({
  label,
  classes,
  getImage,
  removeImage,
  stateName,
  previewUrl,
  file,
  buttonText,
  isIcon,
  tooltip,
  imgId,
  tooltipImageSize,
  defaultImage = null,
  isDisabled = false,
}) => {
  const { t } = useTranslation();
  const [imageDimension, setImageDimension] = useState('');

  const uploadImage = async (state, image) => {
    let data = new FormData();
    data.append('file', image);

    const response = await gastro.post('/media', data);

    getImage(state, response.data);
  };

  return (
    <React.Fragment>
      {imgId && tooltipImageSize && (
        <Tooltip
          onOpen={() => {
            const img = document.getElementById(imgId);
            if (img !== null) {
              const { naturalHeight, naturalWidth } = img;
              const imgSizeDescription = `${naturalWidth}px na ${naturalHeight}px`;
              setImageDimension(imgSizeDescription);
            }
          }}
          style={{ marginLeft: '10px', color: 'grey' }}
          title={
            <div>
              <h4>
                {t('form.menuPlanning.actualPictureSize')}: {imageDimension}
              </h4>
              <h4>
                {t('form.menuPlanning.recommendedPictureSize')}:{' '}
                {tooltipImageSize}
              </h4>
            </div>
          }
          placement="right"
        >
          <Info fontSize={'small'} />
        </Tooltip>
      )}
      {label && (
        <GridItem xs={12}>
          <div
            style={{
              marginLeft: '10px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FormLabel className={classes.labelHorizontal}>{label}</FormLabel>
            {tooltip && (
              <Tooltip
                style={{ marginLeft: '10px', color: 'grey' }}
                title={
                  <div>
                    <h4>{tooltip}</h4>
                  </div>
                }
                placement="right"
              >
                <Info fontSize={'small'} />
              </Tooltip>
            )}
          </div>
        </GridItem>
      )}
      <GridItem xs={12}>
        <ImageUpload
          isIcon={isIcon}
          stateName={stateName}
          getImage={uploadImage}
          removeImage={removeImage}
          previewUrl={
            previewUrl
              ? process.env.REACT_APP_STATIC_FILES_CDN + previewUrl
              : null
          }
          buttonText={buttonText}
          imgId={imgId}
          file={file}
          addButtonProps={{
            color: 'info',
            round: false,
          }}
          changeButtonProps={{
            color: 'info',
            round: false,
          }}
          removeButtonProps={{
            color: 'danger',
            round: false,
          }}
          defaultImage={defaultImage}
          isDisabled={isDisabled}
        />
      </GridItem>
    </React.Fragment>
  );
};

export default FormImageUpload;
