import { useEffect, useState } from 'react';
import AdminTable from 'layouts/AdminTable';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_CREATE_DISCOUNT_CODE,
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_IMPORT_DISCOUNT_CODE,
} from 'helpers/roles';
import { connect } from 'react-redux';
import { fetchDiets } from '../../actions/Diets';
import { compose } from 'redux';
import { Trans, useTranslation } from 'react-i18next';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import { post } from 'helpers/apiHelpers';
import gastro from 'helpers/gastro';
import importCSVExamplePL from './importExample/import-discount-codes-csv-example-pl.csv';
import importCSVExampleEn from './importExample/import-discount-codes-csv-example-en.csv';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
  ...buttonsStyle,
  modalList: {
    textAlign: 'left',
  },
  importButton: {
    marginTop: '10px',
  },
});

const List = ({ diets, history, fetchDiets }) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const { language } = i18n;

  const [state, setState] = useState({
    file: null,
    openedModal: false,
    isImporting: false,
    importButtonClicked: false,
  });

  useEffect(fetchDiets, []);

  const toggleModal = () => {
    setState(prevState => ({
      ...prevState,
      openedModal: !prevState.openedModal,
    }));
  };

  const importIngredients = () => {
    setState(prev => ({ ...prev, importButtonClicked: true }));
    uploadFile(state.file).then(file => {
      postImporterTask(file, state.modalMode);
    });
  };

  const postImporterTask = file => {
    setState(prev => ({ ...prev, isImporting: true }));

    const data = {
      type: 'DISCOUNT_CODES',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data)
      .then(() => {
        setState(prev => ({
          ...prev,
          openedModal: false,
          file: null,
          importButtonClicked: false,
        }));
        setState(prev => ({ ...prev, isImporting: false }));
        return toast.success(
          `${t('form.importerInfo26')} ${t('form.importerInfo23')}`
        );
      })
      .catch(() => {
        setState(prev => ({ ...prev, isImporting: false }));
        return toast.error(t('form.importerInfo25'));
      });
  };

  const uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  const loadExample = language => {
    switch (language) {
      case 'pl':
        return importCSVExamplePL;
      default:
        return importCSVExampleEn;
    }
  };

  const newSize = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable title={t('list.promoCodesList')}>
      <GridContainer>
        <GridItem sm={10}>
          {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
            isGranted(ROLE_IMPORT_DISCOUNT_CODE) && (
              <Button
                className={classes.importButton}
                color="info"
                onClick={toggleModal}
              >
                {t('form.import.discountCodes')}
              </Button>
            )}
        </GridItem>
        <GridItem sm={2}>
          {isGranted(ROLE_CREATE_DISCOUNT_CODE) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={newSize}
              submitText={t('list.generateCode')}
            />
          )}
        </GridItem>
      </GridContainer>
      <DataGrid
        refreshOnFetch
        actions={true}
        previewPath={`${window.location.pathname}/preview`}
        remove={true}
        export={true}
        editPath={`${window.location.pathname}/edit`}
        paginationTop={true}
        paginationBottom={false}
        url="/discount-codes"
        reportName="discountcode"
        columns={columnConfig(diets, t)}
        role="DISCOUNT_CODE"
        manipulateQuery={(_, query) => {
          if (query.discount?.value) {
            query['discount[]'] = query.discount.value;
            query['discount[_operator]'] = 'eq';
          }

          if (
            query.discount?.isPercentage === 'true' ||
            query.discount?.isPercentage === 'false'
          ) {
            query.isPercentage = query.discount.isPercentage;
          }

          query.discount = null;

          return query;
        }}
        canRemoveChecker={(_, row) => row.orders.length === 0}
        defaultFiltered={[{ id: 'archived', value: false }]}
      />

      <InfoModal
        modalState={state.openedModal}
        handleClose={() => toggleModal()}
        modalContent={
          <>
            <h1>{t('discountcode.Import')}</h1>
            <ul className={classes.modalList}>
              <Trans
                i18nKey="discountCode.importerModalInfo"
                components={{
                  br: <br />,
                  li: <li />,
                  strong: <strong />,
                  fileLink: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={loadExample(language)}
                      download={
                        t('discountCode.importerModalInfo.fileName') + '.csv'
                      }
                    />
                  ),
                  libreLink: (
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href="https://www.libreoffice.org/download/download/"
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              />
            </ul>

            <MediaUpload
              file={state}
              setFile={file => setState(prev => ({ ...prev, file: file }))}
              buttonText={t('form.selectCSVFile')}
              addButtonProps={{
                color: 'info',
                round: false,
              }}
              changeButtonProps={{
                color: 'info',
                round: false,
              }}
              removeButtonProps={{
                color: 'danger',
                round: false,
              }}
            />
            <div>
              {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                isGranted(ROLE_IMPORT_DISCOUNT_CODE) && (
                  <Button
                    color={state.file ? 'success' : 'default'}
                    disabled={
                      !state.file ||
                      state.isImporting ||
                      state.importButtonClicked
                    }
                    onClick={importIngredients}
                  >
                    {t('form.import')}
                  </Button>
                )}
              <Button onClick={() => toggleModal()}>{t('form.close')}</Button>
            </div>
          </>
        }
        closeButtonText={t('form.close')}
        hideDefaultCloseButton
      />
    </AdminTable>
  );
};

const mapStateToProps = state => ({
  diets: state.Diets.diets,
});

const mapDispatchToProps = dispatch => ({
  fetchDiets: () => dispatch(fetchDiets()),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(List);
