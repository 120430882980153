import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { Info } from '@material-ui/icons';
import { Divider, Tooltip } from '@material-ui/core';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import LabeledCheckbox from 'components/Checkbox/LabeledCheckbox';
import { usePanelConfigurationContext } from './PanelConfiguration.context';
import Button from 'components/CustomButtons/Button.jsx';
import DialogImages from './components/DialogImages';
import CardWrapper from 'components/Card/CardWrapper';

const DishImageConfig = ({ t, classes }) => {
  const {
    setConfigClientPanelModuleImageByStateName,
    removeConfigClientPanelModuleImageByStateName,
    handleCheckboxChange,
    configClientPanelModule,
    setConfigClientPanelModule,
    choicesBackground,
    setChoicesBackground,
    isOpen,
    setIsOpen,
    choicesAlternatePhotoDish,
    setChoicesAlternatePhotoDish,
    backgroundToChoicedArray,
    backgroundDialogMode,
    setBackgroundDialogMode,
    alternatePhotoToDishArray,
  } = usePanelConfigurationContext();

  return (
    <>
      <CardWrapper
        title={t(
          'brands.newBrandForm.dishImageConfig',
          '$$Konfiguracja zdjęć dań'
        )}
      >
        <CardBody>
          <Divider style={{ marginBottom: '20px' }} />
          <GridContainer>
            <GridItem md={6}>
              <GridContainer>
                <GridItem md={12}>
                  <LabeledCheckbox
                    name="dishImageBackgroundEnabled"
                    checked={
                      configClientPanelModule.dishImageBackgroundEnabled ||
                      false
                    }
                    onClick={() =>
                      handleCheckboxChange(
                        'dishImageBackgroundEnabled',
                        setConfigClientPanelModule
                      )
                    }
                    label={t(
                      'brands.newBrandForm.dishImageBackgroundEnabled',
                      '$$Zastosuj dynamiczne tło'
                    )}
                  />
                </GridItem>
                <GridItem md={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{
                        marginTop: '15px',
                        marginBottom: '15px',
                      }}
                    >
                      {t(
                        'brands.newBrandForm.dishImageBackground',
                        '$$Dynamiczne tło'
                      )}
                    </FormLabel>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <FormImageUpload
                      classes={classes}
                      stateName="dishImageBackground"
                      buttonText={t(
                        'configPanel.dynamicBackground.choice.Button',
                        'Wybierz z dysku'
                      )}
                      getImage={setConfigClientPanelModuleImageByStateName}
                      imgId="dishImageBackground"
                      removeImage={
                        removeConfigClientPanelModuleImageByStateName
                      }
                      previewUrl={
                        choicesBackground?.image?.contentUrl
                          ? choicesBackground?.image?.contentUrl
                          : configClientPanelModule?.['@resources']?.[
                              configClientPanelModule.dishImageBackground
                            ]?.contentUrl
                      }
                    />
                    <div>
                      <Button
                        color="info"
                        onClick={() => {
                          setIsOpen(true);
                          setBackgroundDialogMode('dishImageBackground');
                        }}
                        sime={'lg'}
                      >
                        {t(
                          'configPanel.dynamicBackground.choice.Gallery',
                          'Wybierz z galerii'
                        )}
                      </Button>
                    </div>
                  </div>
                </GridItem>
                <GridItem md={6}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginTop: '15px', marginBottom: '15px' }}
                    >
                      {t(
                        'brands.newBrandForm.dishImagePlaceholder',
                        '$$Zastępcze zdjęcie dania'
                      )}
                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'brands.newBrandForm.dishImagePlaceholderTooltip'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info
                          fontSize="small"
                          style={{
                            color: 'grey',
                            verticalAlign: 'sub',
                            marginLeft: '0.25rem',
                          }}
                        />
                      </Tooltip>
                    </FormLabel>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <FormImageUpload
                      classes={classes}
                      stateName="dishImagePlaceholder"
                      buttonText={t(
                        'configPanel.dynamicBackground.choice.Button',
                        'Wybierz z dysku'
                      )}
                      getImage={setConfigClientPanelModuleImageByStateName}
                      imgId="dishImagePlaceholder"
                      removeImage={
                        removeConfigClientPanelModuleImageByStateName
                      }
                      previewUrl={
                        choicesAlternatePhotoDish?.image?.contentUrl
                          ? choicesAlternatePhotoDish?.image?.contentUrl
                          : configClientPanelModule?.['@resources']?.[
                              configClientPanelModule.dishImagePlaceholder
                            ]?.contentUrl
                      }
                    />
                    <div>
                      <Button
                        color="info"
                        onClick={() => {
                          setIsOpen(true);
                          setBackgroundDialogMode('dishImagePlaceholder');
                        }}
                        sime={'lg'}
                      >
                        {t(
                          'configPanel.dynamicBackground.choice.Gallery',
                          'Wybierz z galerii'
                        )}
                      </Button>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>

      {backgroundDialogMode === 'dishImageBackground' && (
        <DialogImages
          backgroundArray={backgroundToChoicedArray}
          mode="dishImageBackground"
          setConfigClientPanelModule={
            setConfigClientPanelModuleImageByStateName
          }
          choicesImage={choicesBackground}
          setBackground={setChoicesBackground}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          t={t}
        />
      )}

      {backgroundDialogMode === 'dishImagePlaceholder' && (
        <DialogImages
          backgroundArray={alternatePhotoToDishArray}
          mode="dishImagePlaceholder"
          setConfigClientPanelModule={
            setConfigClientPanelModuleImageByStateName
          }
          choicesImage={choicesAlternatePhotoDish}
          setBackground={setChoicesAlternatePhotoDish}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          t={t}
        />
      )}
    </>
  );
};

const enhance = compose(withTranslation(), withStyles(extendedFormsStyle));

export default enhance(DishImageConfig);
