import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import makeStyles from '@material-ui/styles/makeStyles';
import { Fragment, useMemo } from 'react';

const useStyles = makeStyles({
  root: {
    textAlign: 'left',
  },
  line: {
    borderColor: 'RGB(219,219,219)',
  },
  dash: {
    marginLeft: '0.2em',
  },
  heading: {
    marginTop: 0,
    marginBottom: '24px',
  },
});

const keysWithValuesAsArray = [
  'brands',
  'zoneCategories',
  'subBrands',
  'recipeTags',
  'mealCategories',
  'types',
  'drivers',
  'kitchen',
  'department',
  'employee',
  'zones',
];

const standAloneKeys = ['eachRecipeOnNewPage'];

const FiltersModal = ({ filters = {}, options = {}, recurrent = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const translations = useMemo(
    () => ({
      INCOME: t('clientCall.type.incoming'),
      OUTGOING: t('clientCall.type.outgoing'),
      date: t('common.shared.date'),
      brands: t('reportArchive.filters.brands'),
      dateFrom: t('reportArchive.filters.dateFrom'),
      dateTo: t('reportArchive.filters.dateTo'),
      department: t('reportArchive.filters.department'),
      drivers: t('reportArchive.filters.drivers'),
      eachRecipeOnNewPage: t('reportArchive.filters.eachRecipeOnNewPage'),
      employee: t('reportArchive.filters.employee'),
      groupClient: t('reportArchive.filters.groupClient'),
      includeShopElements: t('reportArchive.filters.includeShopElements'),
      includeSubscriptions: t('reportArchive.filters.includeSubscriptions'),
      kitchen: t('reportArchive.filters.kitchen'),
      mealCategories: t('reportArchive.filters.mealCategories'),
      multiplier: t('reportArchive.filters.multiplier'),
      packedDate: t('reportArchive.filters.packedDate'),
      recipeTags: t('reportArchive.filters.recipeTags'),
      shouldBeConsumedTo: t('reportArchive.filters.shouldBeConsumedTo'),
      subBrands: t('reportArchive.filters.subBrands'),
      types: t('reportArchive.filters.types'),
      variant: t('reportArchive.filters.variant'),
      zoneCategories: t('reportArchive.filters.zoneCategories'),
      zones: t('reportArchive.filters.zones'),
      'delivery-point': t('reportArchive.filters.deliveryPoint'),
    }),
    []
  );

  return (
    <div className={classes.root}>
      {!recurrent && (
        <h2 className={classes.heading}>
          {t('reportArchive.filters.heading')}
        </h2>
      )}
      {Object.entries(filters).map(([filterKey, filterValue]) => {
        if (filterKey === 'parameters') {
          return filterValue.map((nestedFilter, index, arr) => (
            <Fragment key={index}>
              <FiltersModal
                filters={nestedFilter}
                options={options}
                recurrent
              />
              {index < arr.length - 1 && <hr className={classes.line} />}
            </Fragment>
          ));
        }

        if (!(filterKey in translations)) {
          return null;
        }

        let formattedFilterValue = filterValue;

        if (keysWithValuesAsArray.includes(filterKey)) {
          formattedFilterValue = filterValue
            .map(value => {
              const matchedOption = options[filterKey]?.find(
                option => option['@id'] === value || option.value === value
              );
              return matchedOption?.name || matchedOption?.value || value;
            })
            .filter(optionName => optionName !== '*')
            .join(', ');
        }

        if (filterValue?.toString() === 'true') {
          formattedFilterValue = t('common.shared.yes').toUpperCase();
        }

        if (filterValue?.toString() === 'false') {
          formattedFilterValue = t('common.shared.no').toUpperCase();
        }

        if (filterKey === 'delivery-point') {
          if (filterValue?.toString() === 'true') {
            formattedFilterValue = t('reports.pickupPointDelivery');
          }

          if (filterValue?.toString() === 'false') {
            formattedFilterValue = t('brands.newBrandForm.toDoor');
          }
        }

        if (filterKey === 'variant') {
          const matchedOption = options.variants?.find(
            ({ id }) => +filterValue === +id
          );

          formattedFilterValue = matchedOption?.name || filterValue;
        }

        if (filterKey.includes('date')) {
          formattedFilterValue = format(new Date(filterValue), 'dd.MM.yyyy');
        }

        return (
          <p key={filterKey}>
            {standAloneKeys.includes(filterKey) && (
              <hr className={classes.line} />
            )}
            <span>{translations[filterKey]}: </span>
            {formattedFilterValue ? (
              <b>{formattedFilterValue}</b>
            ) : (
              <span className={classes.dash}>-</span>
            )}
          </p>
        );
      })}
    </div>
  );
};

export default FiltersModal;
