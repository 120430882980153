import roles from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';

const UsageOfDiscountCodes = () => {
  const { t } = useTranslation();

  const title = t('reports.reportDiscountCodes');

  return (
    <BaseBrandReport
      title={title}
      excelUrl="/reports/usage-discount-codes"
      mimeType="application/vnd.ms-excel"
      fileName={getReportFileName(title)}
      role={roles.ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES}
      pdfAvailable={false}
      excelAvailable={true}
      dateType="range"
      archiveReportNames={['USED_DISCOUNT_CODES']}
      useZoneCategories={false}
    />
  );
};

export default UsageOfDiscountCodes;
