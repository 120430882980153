import { useTranslation } from 'react-i18next';
import { compose } from 'redux';
import { isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_DICTIONARY,
  ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT,
  ROLE_REMOVE_DICTIONARY,
} from 'helpers/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import columnConfigs from './columnConfigs';
import ContainerReplacementSection from './components/ContainerReplacementSection';

import { withTranslation } from 'react-i18next';
import DICTIONARIES_TYPES from 'views/Dictionary/dictionariesTypes.const';
import { useEffect, useState } from 'react';
import { get } from 'helpers/apiHelpers';
import { useSelector } from 'react-redux';
import Button from 'components/CustomButtons/Button';
import { Dialog } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { remove } from 'helpers/apiHelpers';
import { toast } from 'react-toastify';

const List = ({
  type = DICTIONARIES_TYPES.REGULAR,
  classes,
  history,
  valueUrl,
  roleName,
  tableName,
  tableTitle,
  createRole,
  valueAccessor = 'value',
  customRemoveBody = false,
  antiPreferencesKey = null,
}) => {
  const { t } = useTranslation();
  const selectedBrand = useSelector(state => state.Auth.selectedBrand);
  const [antiPreferences, setAntiPreferences] = useState([]);
  const [state, setState] = useState({
    checkedRowIds: [],
    isConfirmModalOpened: false,
    openedModal: false,
    refreshList: false,
    enableMassDelete: false,
  });

  const renderCustomRemoveBody = props => {
    switch (type) {
      case DICTIONARIES_TYPES.CONTAINERS:
        return <ContainerReplacementSection {...props} classes={classes} />;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (
      ['ingredient-categories', 'client-tags', 'tags', 'wholesalers'].includes(
        valueUrl
      )
    ) {
      setState(prevState => ({
        ...prevState,
        enableMassDelete: true,
      }));
    }

    if (isGranted(ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT)) {
      (async () => {
        if (antiPreferencesKey !== null) {
          const {
            configuration: { MenuSelectionAssistant },
          } = await get(
            `brands/${selectedBrand}/modules?module[]=MenuSelectionAssistant`
          );
          setAntiPreferences(MenuSelectionAssistant[antiPreferencesKey]);
        }
      })();
    }
  }, []);

  const refreshList = () => {
    setState(prevState => ({
      ...prevState,
      refreshList: !prevState.refreshList,
    }));
  };

  const massDeleteHandleMultipleCheckboxes = array => {
    setState(prevState => ({ ...prevState, checkedRowIds: array }));
  };

  const massDeleteHandleSingleCheckbox = id => {
    const checkedIdsUpdated = [...state.checkedRowIds];
    const checkedIdIndex = checkedIdsUpdated.indexOf(id);
    if (checkedIdIndex >= 0) {
      checkedIdsUpdated.splice(checkedIdIndex, 1);
    } else {
      checkedIdsUpdated.push(id);
    }
    setState(prevState => ({ ...prevState, checkedRowIds: checkedIdsUpdated }));
  };

  const massDeleteSubmit = () => {
    setState(prevState => ({
      ...prevState,
      isConfirmModalOpened: false,
      isDeleting: false,
    }));

    let url = `/${valueUrl}?`;
    state.checkedRowIds.forEach((id, index) => {
      if (index === 0) {
        url = url.concat(`id[]=${id}`);
      } else {
        url = url.concat(`&id[]=${id}`);
      }
    });

    url = url.concat(`&removeAllAction=true`);
    remove(url, { removeAllAction: true })
      .then(() => {
        toast.success(t('dictionary.massdelete.success'));
        refreshList();
      })
      .catch(() => {
        toast.warning(t('dictionary.massdelete.error'));
      });
  };
  return (
    <AdminTable title={t(tableTitle)}>
      <div style={{ display: 'flex' }}>
        <div style={{ padding: '5px' }}>
          {isGranted(ROLE_REMOVE_DICTIONARY) && state.enableMassDelete && (
            <Button
              data-cy="__remove_dictionary_button"
              color="danger"
              onClick={() =>
                setState(prevState => ({
                  ...prevState,
                  isConfirmModalOpened: true,
                }))
              }
              disabled={state?.checkedRowIds?.length === 0}
            >
              {t(
                'dictionary.deleteDictionaryElement',
                'Usuń zaznaczone elementy'
              )}
            </Button>
          )}
        </div>
        {isGranted(createRole ? createRole : ROLE_CREATE_DICTIONARY) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={() => history.push(`${window.location.pathname}/add`)}
            submitText={`${t('common.mainMenu.listAdd')} ${t(tableName)} +`}
          />
        )}
      </div>
      <DataGrid
        refresh={state.refreshList}
        massDelete={state.enableMassDelete}
        massDeleteItemsIds={state.checkedRowIds}
        massDeleteHandleSingleCheckbox={massDeleteHandleSingleCheckbox}
        massDeleteHandleMultipleCheckboxes={massDeleteHandleMultipleCheckboxes}
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={!state.enableMassDelete}
        export={true}
        renderCustomRemoveBody={
          customRemoveBody ? renderCustomRemoveBody : null
        }
        paginationTop={true}
        paginationBottom={false}
        url={valueUrl}
        columns={columnConfigs({ valueAccessor, type, t, antiPreferences })}
        role={roleName ? roleName : 'DICTIONARY'}
      />
      <Dialog fullWidth={true} open={state.isConfirmModalOpened}>
        <SweetAlert
          warning
          style={{ display: 'block' }}
          title={t('common.shared.areYouSure')}
          onConfirm={massDeleteSubmit}
          onCancel={() =>
            setState(prevState => ({
              ...prevState,
              isConfirmModalOpened: !prevState.isConfirmModalOpened,
            }))
          }
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      </Dialog>
    </AdminTable>
  );
};
const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
