import { useState } from 'react';
import roles from 'helpers/roles';
import GridItem from 'components/Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import SelectAll from 'components/SelectAll';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { useTranslation } from 'react-i18next';
import { callTypes } from '../Clients/FormTabs/Calls/ItemTypes';
import getReportFileName from 'helpers/getReportFileName';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';
import MIME_TYPES from './constants/mimeTypes';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(extendedFormsStyle);

const BillingReport = () => {
  const [selectedTypes, setSelectedTypes] = useState([]);
  const classes = useStyles();
  const { t } = useTranslation();
  const title = t('common.mainMenu.reportsBilling');

  return (
    <BrandDateSubbrandsReport
      useSubBrands={false}
      useDateRange={true}
      title={title}
      url="reports/billing"
      mimeType={MIME_TYPES.xlsx}
      fileName={getReportFileName(title)}
      additionalParams={{ types: selectedTypes.map(type => type.value) }}
      additionalOptions={{ types: callTypes(t) }}
      role={roles.ROLE_SHOW_CLIENT_CALL}
      archiveReportNames={['BILLING']}
    >
      <GridItem sm={12}>
        <FormLabel className={classes.labelHorizontal}>
          {t('clientCalls.type')} *
        </FormLabel>
        <SelectAll
          classes={classes}
          options={callTypes(t)}
          mapBy="name"
          trackBy="value"
          name="type"
          optionSelected={selectedTypes}
          handleChange={setSelectedTypes}
          size={12}
        />
      </GridItem>
    </BrandDateSubbrandsReport>
  );
};

export default BillingReport;
