import { Component, isValidElement } from 'react';

import classNames from 'classnames';
import types from 'helpers/constants';
import withStyles from '@material-ui/core/styles/withStyles';
import { Toast } from 'material-ui-toast-redux';
import PropTypes from 'prop-types';
import qs, { parse } from 'query-string';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import styled from 'styled-components';
import {
  handleBrandChange,
  reloadTranslations,
  logout,
  refreshLogin,
} from 'actions/Auth';
import { get } from 'helpers/apiHelpers';
import AppDialogLoader from '../components/DialogLoader/AppDialogLoader';
import { createHotjar } from 'utils/hotjar';
import { createHelpScout } from 'utils/helpScout';
import { fetchCountryInformations } from 'actions/Brands';
import { getCompany } from 'actions/Company';
import { setRegionForValidations } from 'actions/I18nSection';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import SecuredView from 'components/Security/SecuredView';
import Sidebar from 'components/Sidebar/Sidebar';
import { combineStyles } from 'helpers/helpers';
import routes from 'routes';
import { store } from 'store';
import {
  containerFluid,
  drawerMiniWidth,
  drawerWidth,
  transition,
} from '../assets/jss/material-dashboard-pro-react';
import { hidden } from 'ansi-colors';

const appStyle = theme => ({
  wrapper: {
    position: 'relative',
    top: '0',
    height: '100vh',
    '&:after': {
      display: 'table',
      clear: 'both',
      content: '" "',
    },
  },
  mainPanel: {
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    [theme.breakpoints.up(960)]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
    position: 'relative',
    float: 'right',
    ...transition,
    maxHeight: '100%',
    width: '100%',
    overflowScrolling: 'touch',
  },
  content: {
    marginTop: '40px',
    padding: '25px 15px 80px 15px',
    overflow: hidden,
    minHeight: 'calc(100vh - 123px)',
  },
  container: { ...containerFluid },
  map: {
    marginTop: '70px',
  },
  mainPanelSidebarMini: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
    },
  },
  mainPanelWithPerfectScrollbar: {
    overflow: 'hidden !important',
  },
});

const excludedPathsForAppDialog = [
  '/orders/add',
  '/meals/edit',
  '/meals/add',
  '/recipes/edit',
  '/recipes/add',
  '/prices',
  '/delivery',
  'admin/diets',
  '/admin/clients/edit/:id/address/edit/:id',
  '/admin/clients/edit/:id/address/add',
  '/admin/employees/edit/:id',
  '/admin/products/edit',
];

const StyledToast = styled(Toast)`
  [class*='MuiSnackbarContent-message-'] {
    width: calc(100% - 60px);
  }
`;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesLoaded: false,
      logo: null,
      pathsWithDisabledLoader: [],
      locationPath: null,
    };
  }

  fetchBrandInformation = () => {
    const selectedBrand = this.props.brands.find(
      brand => brand.id === this.props.brand
    );
    if (selectedBrand) {
      get(selectedBrand['@id']).then(async res => {
        store.dispatch({
          type: types.FETCH_BRAND,
          payload: res,
        });

        // TODO: inconsistency - why - company may have different types on dev and on stage
        this.props
          .getCompany(
            typeof res['company'] === 'object'
              ? res['company']['@id']
              : res['company']
          )
          .then(({ name, hotJar: { id, version, enabled } }) => {
            if (enabled) {
              createHotjar(id, version);
            }

            if (!document.getElementById('helpScoutScript')) {
              createHelpScout(
                window.location.href,
                this.props.user,
                this.props.brandInfo,
                name
              );
            }
          });

        await this.props.fetchCountryInformations();
        const supportedCountry = res.multinational.supportedRegions?.[0];
        const defaultSelectedRegionForValidation =
          this.props.countryInformations?.find(
            ({ code }) => supportedCountry === code
          );
        this.props.setRegionForValidations(defaultSelectedRegionForValidation);

        await this.props.reloadTranslations();

        this.setState(prev => ({
          ...prev,
          logo: res.logo,
          brandShortName: res.shortName,
          brandName: res.name,
          rolesLoaded: true,
        }));
      });
    } else if (this.props.brands.length === 0) {
      this.props.logout();
      this.props.history.push('/auth/login');
    }

    const brandFromQuery = parse(this.props.history.location.search)[
      'selectedBrand'
    ];
    const brandToSelect = this.props.brands.find(
      brand => brand.id === +brandFromQuery
    );

    brandToSelect &&
      this.props.handleBrandChange(null, { brand: brandToSelect });
  };

  async componentDidMount() {
    await this.props.refreshLogin();
    this.setState({
      locationPath: window.location.pathname,
    });
    store.dispatch({
      type: types.APP_FETCH_RESET,
    });
    this.fetchBrandInformation();
  }

  componentDidUpdate(e) {
    const searchParams = new URLSearchParams(window.location.search);
    const selectedBrand = parseInt(searchParams.get('selectedBrand'), 10);
    const prevSearchParams = new URLSearchParams(
      this.props.history.location.search
    );
    const prevSelectedBrand = parseInt(
      prevSearchParams.get('selectedBrand'),
      10
    );

    if (!isNaN(prevSelectedBrand) && selectedBrand !== prevSelectedBrand) {
      this.fetchBrandInformation();
    }

    if (e.history.location.pathname !== e.location.pathname) {
      this.setState({
        locationPath: window.location.pathname,
      });
      if (this.state.mobileOpen) {
        this.setState({
          mobileOpen: false,
          locationPath: window.location.pathname,
        });
      }
    }
    this.setSelectedBrand();
  }

  setSelectedBrand = () => {
    const queryParams = qs.parse(this.props.history.location.search);
    const newQueries = { ...queryParams, selectedBrand: this.props.brand };

    if (this.props.history.location.search !== newQueries) {
      window.history.replaceState(
        null,
        null,
        window.location.pathname + '?' + qs.stringify(newQueries)
      );
    }
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== '/admin/full-screen-maps';
  }

  getActiveRoute = () => {
    let sectionTitle = '';
    let sectionPath = '';
    let viewTitle = '';
    let viewPath = '';
    let icon = null;
    let breadcrumbAdditionalContent = null;

    const path = `/${window.location.pathname.split('/')[2]}`;

    const routesWithoutViews = routes.filter(el => !el.collapse);
    routesWithoutViews.forEach(route => {
      if (path === route.path) {
        icon = route.icon;
        sectionTitle = this.props.t(route.name);
        sectionPath = `/admin${route.path}`;
        breadcrumbAdditionalContent = route.breadcrumbAdditionalContent;
      }
    });

    const routesWithViews = routes.filter(el => !!el.collapse);
    routesWithViews.forEach(route => {
      route.views.forEach(view => {
        if (path === view.path) {
          icon = route.icon;
          sectionTitle = this.props.t(route.name);
          viewTitle = this.props.t(view.name);
          viewPath = `/admin${view.path}`;
          breadcrumbAdditionalContent = view.breadcrumbAdditionalContent;
        }
      });
    });

    return {
      sectionTitle,
      sectionPath,
      viewTitle,
      viewPath,
      icon,
      breadcrumbAdditionalContent,
    };
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={props => {
              const Component = (
                <prop.component {...props} {...prop.componentProps} />
              );

              if (prop.role) {
                return (
                  <SecuredView role={prop.role} returnNull={false}>
                    {Component}
                  </SecuredView>
                );
              }

              return Component;
            }}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  setDocTitle = () => {
    let docTitle = `${
      this.state.brandShortName
        ? this.state.brandShortName
        : this.state.brandName
        ? this.state.brandName
        : ''
    } | ${this.getActiveRoute(routes).sectionTitle} > ${
      this.getActiveRoute(routes).viewTitle
    }`;

    const isEdit = window.location.pathname.includes('edit');
    const isAdd =
      window.location.pathname.includes('add') &&
      !window.location.pathname.includes('address');
    const isPreview = window.location.pathname.includes('preview');

    if (isEdit) {
      docTitle += ' > Edycja'; //common.edit
    } else if (isAdd) {
      docTitle += ' > Tworzenie'; //common.add
    } else if (isPreview) {
      docTitle += ' > Podgląd'; //common.preview
    }
    const stringNumbers = window.location.pathname.match(/\d+/g);

    if (stringNumbers && (isEdit || isAdd || isPreview)) {
      const joinedString = stringNumbers.join('-');

      docTitle += ` > ${joinedString}`;
    }

    if (this.state.brandName) {
      document.title = docTitle;
    }
  };

  render() {
    this.setDocTitle();

    const { classes, t } = this.props;

    if (!this.state.rolesLoaded) {
      return null;
    }

    const selectedBrand = this.props.brands.find(
      brand => brand.id === this.props.brand
    );

    return (
      <div className={classes.wrapper}>
        <AppDialogLoader
          text={t('common.loader')}
          excludedPaths={excludedPathsForAppDialog}
        />
        <Sidebar
          isMobileDrawerOpen={this.state.mobileOpen}
          logoText={selectedBrand ? selectedBrand.name : ''}
          handleDrawerToggle={this.handleDrawerToggle}
        />
        <div
          className={classNames(classes.mainPanel, {
            [classes.mainPanelSidebarMini]: this.props.isSidebarMiniModeActive,
          })}
          ref="mainPanel"
        >
          <AdminNavbar
            activeRoute={this.getActiveRoute()}
            handleDrawerToggle={this.handleDrawerToggle}
          />
          {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
          {this.getRoute() ? (
            <div className={classes.content}>
              <div className={classes.container}>
                <Switch>{this.getRoutes(routes)}</Switch>
              </div>
            </div>
          ) : (
            <div className={classes.map}>
              <Switch>{this.getRoutes(routes)}</Switch>
            </div>
          )}
        </div>
        <StyledToast />
        {this.props.sweetAlerts
          .filter(
            el =>
              typeof el.content === 'undefined' || isValidElement(el.content)
          )
          .map((sweetAlert, i) => (
            <SweetAlert
              key={i}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
              cancelBtnCssClass={`${classes.button} ${classes.danger}`}
              {...sweetAlert}
            >
              {sweetAlert.content || ''}
            </SweetAlert>
          ))}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const combinedStyles = combineStyles(
  buttonsStyle,
  extendedFormsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brands: state.Auth.user.brands,
    user: state.Auth.user,
    brand: state.Auth.selectedBrand,
    brandInfo: state.Brands.brand,
    countryInformations: state.Brands.countryInformations,
    sweetAlerts: state.App.sweetAlerts,
    isSidebarMiniModeActive: state.SidebarStatus.isSidebarMiniModeActive,
  };
};

const mapDispatchToProps = {
  handleBrandChange,
  refreshLogin,
  fetchCountryInformations,
  setRegionForValidations,
  logout,
  getCompany,
  reloadTranslations,
};

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  withRouter,
  withStyles(appStyle),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(Dashboard);
