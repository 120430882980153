import React from 'react';
import roles from 'helpers/roles';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from '../BaseBrandReport';
import { useTranslation } from 'react-i18next';

const Income = () => {
  const { t } = useTranslation();
  const title = t('reports.accountant.income');

  return (
    <BaseBrandReport
      title={title}
      excelUrl="/reports/accountant/income"
      mimeType="application/vnd.ms-excel"
      fileName={getReportFileName(title)}
      role={roles.ROLE_SHOW_REPORT_ACTIVE_DIET}
      pdfAvailable={false}
      excelAvailable={true}
      dateType="range"
      archiveReportNames={['ACCOUNTANT_INCOME']}
      useZoneCategories={false}
    />
  );
};

export default Income;
