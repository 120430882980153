import axios from 'axios/index';
import Cookies from 'cookies-js';
import { getI18n } from 'react-i18next';
import { toast } from 'react-toastify';

import types from 'helpers/constants';
import { store } from 'store';

axios.defaults.withCredentials = true;

const gastro = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

gastro.interceptors.request.use(
  request => {
    if (request?.disableLoader !== true) {
      store.dispatch({
        type: types.APP_FETCH_REQUEST,
      });
    }

    const token = localStorage.getItem('t');
    request.headers['Content-Type'] = 'application/json';
    request.headers['X-LOCALE'] =
      request?.forcedLanguage ?? getI18n().language.substr(0, 2);

    if (store.getState().Auth.selectedBrand && !request.headers['X-G-BRAND']) {
      request.headers['X-G-BRAND'] = store.getState().Auth.selectedBrand;
    }

    if (token && token !== '') {
      request.headers.common['Authorization'] = `Bearer ${token}`;
    }

    if (process.env.NODE_ENV !== 'production') {
      if (request.url.indexOf && request.url.indexOf('?') > -1) {
        request.url = request.url + '&XDEBUG_SESSION_START=PHPSTORM';
      } else {
        request.url = request.url + '?XDEBUG_SESSION_START=PHPSTORM';
      }
    }

    return request;
  },
  error => {
    Promise.reject(error);
  }
);

gastro.interceptors.response.use(
  response => {
    if (
      response.config.method === 'get' &&
      Cookies.get('appv') !== response.headers['x-cv'] &&
      ['/my-roles', '/my-link'].filter(
        route => !response.config.url.includes(route)
      ).length === 0
    ) {
      Cookies.set('appv', response.headers['x-cv']);
      window.location.reload();
    }

    if (response.config?.disableLoader !== true) {
      store.dispatch({
        type: types.APP_FETCH_SUCCESS,
      });
    }
    return response;
  },
  error => {
    store.dispatch({
      type: types.APP_FETCH_FAILURE,
    });

    if (error?.response) {
      const { data, status } = error.response;

      const collectErrorMessages400 = () => {
        const { 'hydra:description': description = '', violations = [] } = data;
        const allErrorMessages =
          violations.length > 0
            ? violations.reduce((acc, { message, propertyPath }) => {
                return [
                  ...acc,
                  propertyPath ? `${message} (${propertyPath})` : message,
                ];
              }, []) || []
            : [description];

        return allErrorMessages;
      };

      const collectErrorMessages404 = () => {
        const message = data.detail
          ? `${data.detail}: ${data.title}`
          : `${data['hydra:description']}: ${data['hydra:title']}`;

        return [message];
      };

      const toastMessages = {
        400: collectErrorMessages400(),
        401: [`Authorization: ${data.message}`],
        403: [`Authorization: ${data['hydra:description']}`],
        404: collectErrorMessages404(),
        422: collectErrorMessages400(),
        500: ['Server: Internal Server Error'],
      };

      const toastMessage = toastMessages[status] || ['Undefined Error'];

      if ([400, 403, 404, 500].includes(status)) {
        toastMessage.forEach(message => {
          toast.error(message);
        });
      } else if (
        status === 401 &&
        !window.location.href.includes('login') &&
        !error.config.url.includes('/refresh_token')
      ) {
        const originalRequest = error.config;

        if (!originalRequest._retry) {
          originalRequest._retry = true;

          return gastro
            .post('/refresh_token', {
              refreshToken: store.getState().Auth.refreshToken,
            })
            .then(
              ({ data: { token, refreshToken } }) => {
                localStorage.setItem('t', token);
                localStorage.setItem('rt', refreshToken);
                originalRequest.headers['Authorization'] = `Bearer ${token}`;

                return gastro(originalRequest);
              },
              () => {
                toastMessages[401].forEach(message => {
                  toast.error(message);
                });

                return (window.location.href = '/auth/login');
              }
            );
        }
      }
    }

    return Promise.reject(error);
  }
);

export default gastro;
