import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import roles from 'helpers/roles';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';

const Overproduction = () => {
  const { t } = useTranslation();
  const title = t('reports.reportAddOrders');

  return (
    <BrandDateSubbrandsReport
      title={title}
      url="reports/overproduction"
      mimeType="application/vnd.ms-excel"
      fileName={getReportFileName(title)}
      role={roles.ROLE_SHOW_REPORT_OVERPRODUCTION}
      useDateRange={true}
      useSubBrands={true}
      useBrands={false}
      showMultiplier={false}
      archiveReportNames={['OVERPRODUCTION']}
    />
  );
};

export default Overproduction;
