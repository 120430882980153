export const ROLE_CLIENT = 'ROLE_CLIENT';
export const ROLE_EMPLOYEE = 'ROLE_EMPLOYEE';
export const ROLE_SHOW_CLIENT_CALL = 'ROLE_SHOW_CLIENT_CALL';
export const ROLE_CREATE_CLIENT_CALL = 'ROLE_CREATE_CLIENT_CALL';
export const ROLE_EDIT_CLIENT_CALL = 'ROLE_EDIT_CLIENT_CALL';
export const ROLE_REMOVE_CLIENT_CALL = 'ROLE_REMOVE_CLIENT_CALL';
export const ROLE_EXPORT_CLIENT_CALL = 'ROLE_EXPORT_CLIENT_CALL';
export const ROLE_SHOW_MENU_PLANNING = 'ROLE_SHOW_MENU_PLANNING';
export const ROLE_CREATE_MENU_PLANNING = 'ROLE_CREATE_MENU_PLANNING';
export const ROLE_REMOVE_MENU_PLANNING = 'ROLE_REMOVE_MENU_PLANNING';
export const ROLE_EXPORT_MENU_PLANNING = 'ROLE_EXPORT_MENU_PLANNING';
export const ROLE_SHOW_MENU_SUMMARY = 'ROLE_SHOW_MENU_SUMMARY';
export const ROLE_AUTO_FILL_MENU_PLANNING = 'ROLE_AUTO_FILL_MENU_PLANNING';
export const ROLE_SHOW_SYSTEM_ADVANCED_DISH = 'ROLE_SHOW_SYSTEM_ADVANCED_DISH';
export const ROLE_SHOW_SIZE = 'ROLE_SHOW_SIZE';
export const ROLE_CREATE_SIZE = 'ROLE_CREATE_SIZE';
export const ROLE_EDIT_SIZE = 'ROLE_EDIT_SIZE';
export const ROLE_REMOVE_SIZE = 'ROLE_REMOVE_SIZE';
export const ROLE_EXPORT_SIZE = 'ROLE_EXPORT_SIZE';
export const ROLE_SHOW_MEAL_TYPE = 'ROLE_SHOW_MEAL_TYPE';
export const ROLE_CREATE_MEAL_TYPE = 'ROLE_CREATE_MEAL_TYPE';
export const ROLE_EDIT_MEAL_TYPE = 'ROLE_EDIT_MEAL_TYPE';
export const ROLE_REMOVE_MEAL_TYPE = 'ROLE_REMOVE_MEAL_TYPE';
export const ROLE_EXPORT_MEAL_TYPE = 'ROLE_EXPORT_MEAL_TYPE';
export const ROLE_SHOW_DIET = 'ROLE_SHOW_DIET';
export const ROLE_CREATE_DIET = 'ROLE_CREATE_DIET';
export const ROLE_EDIT_DIET = 'ROLE_EDIT_DIET';
export const ROLE_REMOVE_DIET = 'ROLE_REMOVE_DIET';
export const ROLE_EXPORT_DIET = 'ROLE_EXPORT_DIET';
export const ROLE_SHOW_VARIANT = 'ROLE_SHOW_VARIANT';
export const ROLE_CREATE_VARIANT = 'ROLE_CREATE_VARIANT';
export const ROLE_EDIT_VARIANT = 'ROLE_EDIT_VARIANT';
export const ROLE_REMOVE_VARIANT = 'ROLE_REMOVE_VARIANT';
export const ROLE_EXPORT_VARIANT = 'ROLE_EXPORT_VARIANT';
export const ROLE_SHOW_INGREDIENT = 'ROLE_SHOW_INGREDIENT';
export const ROLE_CREATE_INGREDIENT = 'ROLE_CREATE_INGREDIENT';
export const ROLE_EDIT_INGREDIENT = 'ROLE_EDIT_INGREDIENT';
export const ROLE_REMOVE_INGREDIENT = 'ROLE_REMOVE_INGREDIENT';
export const ROLE_EXPORT_INGREDIENT = 'ROLE_EXPORT_INGREDIENT';
export const ROLE_IMPORT_INGREDIENT = 'ROLE_IMPORT_INGREDIENT';
export const ROLE_SHOW_PRODUCT = 'ROLE_SHOW_PRODUCT';
export const ROLE_EDIT_PRODUCT = 'ROLE_EDIT_PRODUCT';
export const ROLE_SHOW_RECIPE = 'ROLE_SHOW_RECIPE';
export const ROLE_CREATE_RECIPE = 'ROLE_CREATE_RECIPE';
export const ROLE_SHOW_ADDITIONAL_OFFER = 'ROLE_SHOW_ADDITIONAL_OFFER';
export const ROLE_EDIT_RECIPE = 'ROLE_EDIT_RECIPE';
export const ROLE_REMOVE_RECIPE = 'ROLE_REMOVE_RECIPE';
export const ROLE_NOTE_RECIPE = 'ROLE_NOTE_RECIPE';
export const ROLE_EDIT_MODULE_DISH_PDF_GENERATOR =
  'ROLE_EDIT_MODULE_DISH_PDF_GENERATOR';
export const ROLE_SHOW_MODULE_DISH_PDF_GENERATOR =
  'ROLE_SHOW_MODULE_DISH_PDF_GENERATOR';
export const ROLE_EDIT_MODULE_MENU_SELECTION_ASSISTANT =
  'ROLE_EDIT_MODULE_MENU_SELECTION_ASSISTANT';
export const ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT =
  'ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT';
export const ROLE_EDIT_MODULE_SHOP = 'ROLE_EDIT_MODULE_SHOP';
export const ROLE_SHOW_MODULE_SHOP = 'ROLE_SHOW_MODULE_SHOP';
export const ROLE_EXPORT_RECIPE = 'ROLE_EXPORT_RECIPE';
export const ROLE_SHOW_DISH = 'ROLE_SHOW_DISH';
export const ROLE_CREATE_DISH = 'ROLE_CREATE_DISH';
export const ROLE_EDIT_DISH = 'ROLE_EDIT_DISH';
export const ROLE_REMOVE_DISH = 'ROLE_REMOVE_DISH';
export const ROLE_EXPORT_DISH = 'ROLE_EXPORT_DISH';
export const ROLE_SHOW_DICTIONARY = 'ROLE_SHOW_DICTIONARY';
export const ROLE_CREATE_DICTIONARY = 'ROLE_CREATE_DICTIONARY';
export const ROLE_EDIT_DICTIONARY = 'ROLE_EDIT_DICTIONARY';
export const ROLE_REMOVE_DICTIONARY = 'ROLE_REMOVE_DICTIONARY';
export const ROLE_EXPORT_DICTIONARY = 'ROLE_EXPORT_DICTIONARY';
export const ROLE_SHOW_PICK_UP_POINT = 'ROLE_SHOW_PICK_UP_POINT';
export const ROLE_CREATE_PICK_UP_POINT = 'ROLE_CREATE_PICK_UP_POINT';
export const ROLE_EDIT_PICK_UP_POINT = 'ROLE_EDIT_PICK_UP_POINT';
export const ROLE_REMOVE_PICK_UP_POINT = 'ROLE_REMOVE_PICK_UP_POINT';
export const ROLE_SHOW_BAG_CONTAINER = 'ROLE_SHOW_BAG_CONTAINER';
export const ROLE_CREATE_BAG_CONTAINER = 'ROLE_CREATE_BAG_CONTAINER';
export const ROLE_EDIT_BAG_CONTAINER = 'ROLE_EDIT_BAG_CONTAINER';
export const ROLE_REMOVE_BAG_CONTAINER = 'ROLE_REMOVE_BAG_CONTAINER';
export const ROLE_EXPORT_BAG_CONTAINER = 'ROLE_EXPORT_BAG_CONTAINER';
export const ROLE_EDIT_COMPANY = 'ROLE_EDIT_COMPANY';
export const ROLE_SHOW_USER = 'ROLE_SHOW_USER';
export const ROLE_CREATE_USER = 'ROLE_CREATE_USER';
export const ROLE_EDIT_USER = 'ROLE_EDIT_USER';
export const ROLE_REMOVE_USER = 'ROLE_REMOVE_USER';
export const ROLE_EXPORT_USER = 'ROLE_EXPORT_USER';
export const ROLE_IMPORT_USER = 'ROLE_IMPORT_USER';
export const ROLE_SHOW_EMPLOYEE = 'ROLE_SHOW_EMPLOYEE';
export const ROLE_CREATE_EMPLOYEE = 'ROLE_CREATE_EMPLOYEE';
export const ROLE_EDIT_EMPLOYEE = 'ROLE_EDIT_EMPLOYEE';
export const ROLE_REMOVE_EMPLOYEE = 'ROLE_REMOVE_EMPLOYEE';
export const ROLE_EXPORT_EMPLOYEE = 'ROLE_EXPORT_EMPLOYEE';
export const ROLE_IMPERSONATE_USER = 'ROLE_ALLOWED_TO_SWITCH';
export const ROLE_SHOW_BRAND = 'ROLE_SHOW_BRAND';
export const ROLE_SHOW_BRAND_POWER_BI = 'ROLE_SHOW_BRAND_POWER_BI';
export const ROLE_CREATE_BRAND = 'ROLE_CREATE_BRAND';
export const ROLE_EDIT_BRAND = 'ROLE_EDIT_BRAND';
export const ROLE_REMOVE_BRAND = 'ROLE_REMOVE_BRAND';
export const ROLE_EXPORT_BRAND = 'ROLE_EXPORT_BRAND';
export const ROLE_SHOW_ROLE = 'ROLE_SHOW_ROLE';
export const ROLE_CREATE_ROLE = 'ROLE_CREATE_ROLE';
export const ROLE_EDIT_ROLE = 'ROLE_EDIT_ROLE';
export const ROLE_REMOVE_ROLE = 'ROLE_REMOVE_ROLE';
export const ROLE_EXPORT_ROLE = 'ROLE_EXPORT_ROLE';
export const ROLE_SHOW_CLIENT_METRIC = 'ROLE_SHOW_CLIENT_METRIC';
export const ROLE_REMOVE_CLIENT_METRIC = 'ROLE_REMOVE_CLIENT_METRIC';

/** Zone Category */
export const ROLE_SHOW_ZONE_CATEGORY = 'ROLE_SHOW_ZONE_CATEGORY';
export const ROLE_CREATE_ZONE_CATEGORY = 'ROLE_CREATE_ZONE_CATEGORY';
export const ROLE_EDIT_ZONE_CATEGORY = 'ROLE_EDIT_ZONE_CATEGORY';
export const ROLE_REMOVE_ZONE_CATEGORY = 'ROLE_REMOVE_ZONE_CATEGORY';
export const ROLE_EXPORT_ZONE_CATEGORY = 'ROLE_EXPORT_ZONE_CATEGORY';

/** Zone */
export const ROLE_SHOW_ZONE = 'ROLE_SHOW_ZONE';
export const ROLE_CREATE_ZONE = 'ROLE_CREATE_ZONE';
export const ROLE_EDIT_ZONE = 'ROLE_EDIT_ZONE';
export const ROLE_REMOVE_ZONE = 'ROLE_REMOVE_ZONE';
export const ROLE_EXPORT_ZONE = 'ROLE_EXPORT_ZONE';
export const ROLE_IMPORT_ZONE = 'ROLE_IMPORT_ZONE';

export const ROLE_SHOW_COST = 'ROLE_SHOW_COST';
export const ROLE_CREATE_COST = 'ROLE_CREATE_COST';
export const ROLE_EDIT_COST = 'ROLE_EDIT_COST';
export const ROLE_REMOVE_COST = 'ROLE_REMOVE_COST';
export const ROLE_EXPORT_COST = 'ROLE_EXPORT_COST';
export const ROLE_IMPORT_COST = 'ROLE_IMPORT_COST';
export const ROLE_SHOW_ADDRESS = 'ROLE_SHOW_ADDRESS';
export const ROLE_CREATE_ADDRESS = 'ROLE_CREATE_ADDRESS';
export const ROLE_EDIT_ADDRESS = 'ROLE_EDIT_ADDRESS';
export const ROLE_REMOVE_ADDRESS = 'ROLE_REMOVE_ADDRESS';
export const ROLE_EXPORT_ADDRESS = 'ROLE_EXPORT_ADDRESS';
export const ROLE_SHOW_DISCOUNT_CODE = 'ROLE_SHOW_DISCOUNT_CODE';
export const ROLE_CREATE_DISCOUNT_CODE = 'ROLE_CREATE_DISCOUNT_CODE';
export const ROLE_EDIT_DISCOUNT_CODE = 'ROLE_EDIT_DISCOUNT_CODE';
export const ROLE_REMOVE_DISCOUNT_CODE = 'ROLE_REMOVE_DISCOUNT_CODE';
export const ROLE_IMPORT_DISCOUNT_CODE = 'ROLE_IMPORT_DISCOUNT_CODE';
export const ROLE_EXPORT_DISCOUNT_CODE = 'ROLE_EXPORT_DISCOUNT_CODE';
export const ROLE_SHOW_ORDER = 'ROLE_SHOW_ORDER';
export const ROLE_EDIT_ORDER = 'ROLE_EDIT_ORDER';
export const ROLE_CREATE_ORDER = 'ROLE_CREATE_ORDER';
export const ROLE_EXPORT_ORDER = 'ROLE_EXPORT_ORDER';
export const ROLE_IMPORT_ORDER = 'ROLE_IMPORT_ORDER';
export const ROLE_SHOW_BASKET = 'ROLE_SHOW_BASKET';
export const ROLE_SHOW_BAG = 'ROLE_SHOW_BAG';
export const ROLE_SHOW_DRIVER = 'ROLE_SHOW_DRIVER';
export const ROLE_CREATE_DRIVER = 'ROLE_CREATE_DRIVER';
export const ROLE_EDIT_DRIVER = 'ROLE_EDIT_DRIVER';
export const ROLE_REMOVE_DRIVER = 'ROLE_REMOVE_DRIVER';
export const ROLE_EXPORT_DRIVER = 'ROLE_EXPORT_DRIVER';
export const ROLE_SHOW_MONEY_BOX_HISTORY = 'ROLE_SHOW_MONEY_BOX_HISTORY';
export const ROLE_CREATE_MONEY_BOX_HISTORY = 'ROLE_CREATE_MONEY_BOX_HISTORY';
export const ROLE_EDIT_MONEY_BOX_HISTORY = 'ROLE_EDIT_MONEY_BOX_HISTORY';
export const ROLE_REMOVE_MONEY_BOX_HISTORY = 'ROLE_REMOVE_MONEY_BOX_HISTORY';
export const ROLE_EXPORT_MONEY_BOX_HISTORY = 'ROLE_EXPORT_MONEY_BOX_HISTORY';
export const ROLE_SHOW_MONEY_BOX_CONFIGURATION =
  'ROLE_SHOW_MONEY_BOX_CONFIGURATION';
export const ROLE_CREATE_MONEY_BOX_CONFIGURATION =
  'ROLE_CREATE_MONEY_BOX_CONFIGURATION';
export const ROLE_EDIT_MONEY_BOX_CONFIGURATION =
  'ROLE_EDIT_MONEY_BOX_CONFIGURATION';
export const ROLE_REMOVE_MONEY_BOX_CONFIGURATION =
  'ROLE_REMOVE_MONEY_BOX_CONFIGURATION';
export const ROLE_EXPORT_MONEY_BOX_CONFIGURATION =
  'ROLE_EXPORT_MONEY_BOX_CONFIGURATION';
export const ROLE_SHOW_MAIL_CONFIGURATION = 'ROLE_SHOW_MAIL_CONFIGURATION';
export const ROLE_CREATE_MAIL_CONFIGURATION = 'ROLE_CREATE_MAIL_CONFIGURATION';
export const ROLE_EDIT_MAIL_CONFIGURATION = 'ROLE_EDIT_MAIL_CONFIGURATION';
export const ROLE_REMOVE_MAIL_CONFIGURATION = 'ROLE_REMOVE_MAIL_CONFIGURATION';
export const ROLE_EXPORT_MAIL_CONFIGURATION = 'ROLE_EXPORT_MAIL_CONFIGURATION';
export const ROLE_SHOW_SMS_CONFIGURATION = 'ROLE_SHOW_SMS_CONFIGURATION';
export const ROLE_CREATE_SMS_CONFIGURATION = 'ROLE_CREATE_SMS_CONFIGURATION';
export const ROLE_EDIT_SMS_CONFIGURATION = 'ROLE_EDIT_SMS_CONFIGURATION';
export const ROLE_REMOVE_SMS_CONFIGURATION = 'ROLE_REMOVE_SMS_CONFIGURATION';
export const ROLE_EXPORT_SMS_CONFIGURATION = 'ROLE_EXPORT_SMS_CONFIGURATION';
export const ROLE_SHOW_END_OF_DIET_REMINDER = 'ROLE_SHOW_END_OF_DIET_REMINDER';
export const ROLE_CREATE_END_OF_DIET_REMINDER =
  'ROLE_CREATE_END_OF_DIET_REMINDER';
export const ROLE_EDIT_END_OF_DIET_REMINDER = 'ROLE_EDIT_END_OF_DIET_REMINDER';
export const ROLE_REMOVE_END_OF_DIET_REMINDER =
  'ROLE_REMOVE_END_OF_DIET_REMINDER';
export const ROLE_EXPORT_END_OF_DIET_REMINDER =
  'ROLE_EXPORT_END_OF_DIET_REMINDER';
export const ROLE_SHOW_SMS = 'ROLE_SHOW_SMS';
export const ROLE_CREATE_SMS = 'ROLE_CREATE_SMS';
export const ROLE_SHOW_NOTE = 'ROLE_SHOW_NOTE';
export const ROLE_CREATE_NOTE = 'ROLE_CREATE_NOTE';
export const ROLE_EXPORT_NOTE = 'ROLE_EXPORT_NOTE';
export const ROLE_SHOW_EMAIL = 'ROLE_SHOW_EMAIL';
export const ROLE_CREATE_EMAIL = 'ROLE_CREATE_EMAIL';
export const ROLE_SHOW_RATE = 'ROLE_SHOW_RATE';
export const ROLE_SHOW_REPORT_EMPLOYEE = 'ROLE_SHOW_REPORT_EMPLOYEE';
export const ROLE_SHOW_REPORT_SENT_SMS = 'ROLE_SHOW_REPORT_SENT_SMS';
export const ROLE_SHOW_REPORT_ACCOUNTANT_INCOME =
  'ROLE_SHOW_REPORT_ACCOUNTANT_INCOME';
export const ROLE_SHOW_REPORT_ACCOUNTANT_NEW_ACCOUNTS =
  'ROLE_SHOW_REPORT_ACCOUNTANT_NEW_ACCOUNTS';
export const ROLE_SHOW_REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS =
  'ROLE_SHOW_REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS';
export const ROLE_SHOW_REPORT_EMPLOYEE_TIME_ENTRIES =
  'ROLE_SHOW_REPORT_EMPLOYEE_TIME_ENTRIES';
export const ROLE_SHOW_REPORT_DISH_LIST = 'ROLE_SHOW_REPORT_DISH_LIST';
export const ROLE_SHOW_REPORT_DISH_STICKERS = 'ROLE_SHOW_REPORT_DISH_STICKERS';
export const ROLE_SHOW_REPORT_BAG_STICKERS = 'ROLE_SHOW_REPORT_BAG_STICKERS';
export const ROLE_SHOW_REPORT_BAG_STICKERS_MINI =
  'ROLE_SHOW_REPORT_BAG_STICKERS_MINI';
export const ROLE_SHOW_REPORT_DISHES_CARD = 'ROLE_SHOW_REPORT_DISHES_CARD';
export const ROLE_SHOW_REPORT_RECIPES_CARD = 'ROLE_SHOW_REPORT_RECIPES_CARD';
export const ROLE_SHOW_REPORT_RECIPES_INGREDIENTS =
  'ROLE_SHOW_REPORT_RECIPES_INGREDIENTS';
export const ROLE_SHOW_REPORT_FOR_DRIVER = 'ROLE_SHOW_REPORT_FOR_DRIVER';
export const ROLE_SHOW_REPORT_SHOPPING = 'ROLE_SHOW_REPORT_SHOPPING';
export const ROLE_SHOW_REPORT_CONTAINERS = 'ROLE_SHOW_REPORT_CONTAINERS';
export const ROLE_SHOW_REPORT_CHECKLIST_RECIPES =
  'ROLE_SHOW_REPORT_CHECKLIST_RECIPES';
export const ROLE_SHOW_REPORT_CHECKLIST_DISHES =
  'ROLE_SHOW_REPORT_CHECKLIST_DISHES';
export const ROLE_SHOW_REPORT_CONTAINERS_DETAIL =
  'ROLE_SHOW_REPORT_CONTAINERS_DETAIL';
export const ROLE_SHOW_REPORT_OVERPRODUCTION =
  'ROLE_SHOW_REPORT_OVERPRODUCTION';
export const ROLE_SHOW_REPORT_WASTE = 'ROLE_SHOW_REPORT_WASTE';
export const ROLE_SHOW_REPORT_INCOME_SUMMARY =
  'ROLE_SHOW_REPORT_INCOME_SUMMARY';
export const ROLE_SHOW_REPORT_FOOD_COST = 'ROLE_SHOW_REPORT_FOOD_COST';
export const ROLE_SHOW_REPORT_FOOD_COST_BAGS =
  'ROLE_SHOW_REPORT_FOOD_COST_BAGS';
export const ROLE_SHOW_REPORT_PURCHASED_ADDONS =
  'ROLE_SHOW_REPORT_PURCHASED_ADDONS';
export const ROLE_SHOW_REPORT_PURCHASED_ADDONS_BY_CLIENT =
  'ROLE_SHOW_REPORT_PURCHASED_ADDONS_BY_CLIENT';
export const ROLE_SHOW_REPORT_ADVANCED_DISH_PDF =
  'ROLE_SHOW_REPORT_ADVANCED_DISH_PDF';
export const ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION =
  'ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION';
export const ROLE_SHOW_REPORT_ACTIVE_DIET = 'ROLE_SHOW_REPORT_ACTIVE_DIET';
export const ROLE_SHOW_REPORT_ACTIVE_DIET_TABLE =
  'ROLE_SHOW_REPORT_ACTIVE_DIET_TABLE';
export const ROLE_SHOW_REPORT_ORDERED_DIET = 'ROLE_SHOW_REPORT_ORDERED_DIET';
export const ROLE_SHOW_REPORT_PERSONAL_PICKUPS =
  'ROLE_SHOW_REPORT_PERSONAL_PICKUPS';
export const ROLE_SHOW_REPORT_PACKERS = 'ROLE_SHOW_REPORT_PACKERS';
export const ROLE_SHOW_REPORT_RECIPE_STICKERS =
  'ROLE_SHOW_REPORT_RECIPE_STICKERS';
export const ROLE_SHOW_REPORT_DISPOSAL = 'ROLE_SHOW_REPORT_DISPOSAL';
export const ROLE_SHOW_NOTIFICATION = 'ROLE_SHOW_NOTIFICATION';
export const ROLE_CREATE_NOTIFICATION = 'ROLE_CREATE_NOTIFICATION';
export const ROLE_EDIT_NOTIFICATION = 'ROLE_EDIT_NOTIFICATION';
export const ROLE_REMOVE_NOTIFICATION = 'ROLE_REMOVE_NOTIFICATION';
export const ROLE_EXPORT_NOTIFICATION = 'ROLE_EXPORT_NOTIFICATION';
export const ROLE_SHOW_SHOW_MENU_PAGE = 'ROLE_SHOW_SHOW_MENU_PAGE';
export const ROLE_EDIT_SHOW_MENU_PAGE = 'ROLE_EDIT_SHOW_MENU_PAGE';
export const ROLE_SHOW_MASS_SMS = 'ROLE_SHOW_MASS_SMS';
export const ROLE_CREATE_MASS_SMS = 'ROLE_CREATE_MASS_SMS';
export const ROLE_EDIT_MASS_SMS = 'ROLE_EDIT_MASS_SMS';
export const ROLE_REMOVE_MASS_SMS = 'ROLE_REMOVE_MASS_SMS';
export const ROLE_EXPORT_MASS_SMS = 'ROLE_EXPORT_MASS_SMS';
export const ROLE_SHOW_MASS_MAIL = 'ROLE_SHOW_MASS_MAIL';
export const ROLE_CREATE_MASS_MAIL = 'ROLE_CREATE_MASS_MAIL';
export const ROLE_EDIT_MASS_MAIL = 'ROLE_EDIT_MASS_MAIL';
export const ROLE_EXPORT_MASS_MAIL = 'ROLE_EXPORT_MASS_MAIL';
export const ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS =
  'ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS';
export const ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS =
  'ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS';
export const ROLE_SHOW_PAGE = 'ROLE_SHOW_PAGE';
export const ROLE_CREATE_PAGE = 'ROLE_CREATE_PAGE';
export const ROLE_EDIT_PAGE = 'ROLE_EDIT_PAGE';
export const ROLE_REMOVE_PAGE = 'ROLE_REMOVE_PAGE';
export const ROLE_EXPORT_PAGE = 'ROLE_EXPORT_PAGE';
export const ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES =
  'ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES';
export const ROLE_SHOW_REPORT_MONEY_BOX_TOP_UP =
  'ROLE_SHOW_REPORT_MONEY_BOX_TOP_UP';
export const ROLE_SHOW_IMPORTER_TASK = 'ROLE_SHOW_IMPORTER_TASK';
export const ROLE_CREATE_IMPORTER_TASK = 'ROLE_CREATE_IMPORTER_TASK';
export const ROLE_REMOVE_IMPORTER_TASK = 'ROLE_REMOVE_IMPORTER_TASK';
export const ROLE_EXPORT_IMPORTER_TASK = 'ROLE_EXPORT_IMPORTER_TASK';
export const ROLE_CREATE_PACKAGE = 'ROLE_CREATE_PACKAGE';
export const ROLE_EDIT_PACKAGE = 'ROLE_EDIT_PACKAGE';
export const ROLE_SHOW_PACKAGE = 'ROLE_SHOW_PACKAGE';
export const ROLE_REMOVE_PACKAGE = 'ROLE_REMOVE_PACKAGE';
export const ROLE_EXPORT_PACKAGE = 'ROLE_EXPORT_PACKAGE';
export const ROLE_SHOW_PACKAGE_LINE_FILTER = 'ROLE_SHOW_PACKAGE_LINE_FILTER';
export const ROLE_EDIT_PACKAGE_LINE_FILTER = 'ROLE_EDIT_PACKAGE_LINE_FILTER';
export const ROLE_REMOVE_PACKAGE_LINE_FILTER =
  'ROLE_REMOVE_PACKAGE_LINE_FILTER';
export const ROLE_CREATE_PACKAGE_LINE_FILTER =
  'ROLE_CREATE_PACKAGE_LINE_FILTER';
export const ROLE_SHOW_OVERPRODUCTION = 'ROLE_SHOW_OVERPRODUCTION';
export const ROLE_CREATE_OVERPRODUCTION = 'ROLE_CREATE_OVERPRODUCTION';
export const ROLE_EDIT_OVERPRODUCTION = 'ROLE_EDIT_OVERPRODUCTION';
export const ROLE_REMOVE_OVERPRODUCTION = 'ROLE_REMOVE_OVERPRODUCTION';
export const ROLE_EXPORT_OVERPRODUCTION = 'ROLE_EXPORT_OVERPRODUCTION';
export const ROLE_SHOW_SUB_BRAND = 'ROLE_SHOW_SUB_BRAND';
export const ROLE_CREATE_SUB_BRAND = 'ROLE_CREATE_SUB_BRAND';
export const ROLE_EDIT_SUB_BRAND = 'ROLE_EDIT_SUB_BRAND';
export const ROLE_REMOVE_SUB_BRAND = 'ROLE_REMOVE_SUB_BRAND';
export const ROLE_EXPORT_SUB_BRAND = 'ROLE_EXPORT_SUB_BRAND';
export const ROLE_SHOW_HOVER = 'ROLE_SHOW_HOVER';
export const ROLE_EDIT_HOVER = 'ROLE_EDIT_HOVER';
export const ROLE_SHOW_WASTE = 'ROLE_SHOW_WASTE';
export const ROLE_CREATE_WASTE = 'ROLE_CREATE_WASTE';
export const ROLE_EDIT_WASTE = 'ROLE_EDIT_WASTE';
export const ROLE_REMOVE_WASTE = 'ROLE_REMOVE_WASTE';
export const ROLE_EXPORT_WASTE = 'ROLE_EXPORT_WASTE';
export const ROLE_SHOW_SEO = 'ROLE_SHOW_SEO';
export const ROLE_EDIT_SEO = 'ROLE_EDIT_SEO';
export const ROLE_SHOW_STATISTICS_DIET = 'ROLE_SHOW_STATISTICS_DIET';
export const ROLE_SHOW_STATISTICS_BAGS = 'ROLE_SHOW_STATISTICS_BAGS';
export const ROLE_SHOW_STATISTICS_INCOMES = 'ROLE_SHOW_STATISTICS_INCOMES';
export const ROLE_SHOW_STATISTICS_SEX = 'ROLE_SHOW_STATISTICS_SEX';
export const ROLE_SHOW_STATISTICS_KNOW_ABOUT =
  'ROLE_SHOW_STATISTICS_KNOW_ABOUT';
export const ROLE_SHOW_MAILING = 'ROLE_SHOW_MAILING';
export const ROLE_EDIT_MAILING = 'ROLE_EDIT_MAILING';
export const ROLE_SHOW_SMSING = 'ROLE_SHOW_SMSING';
export const ROLE_EDIT_SMSING = 'ROLE_EDIT_SMSING';
export const ROLE_SHOW_ADDON = 'ROLE_SHOW_ADDON';
export const ROLE_CREATE_ADDON = 'ROLE_CREATE_ADDON';
export const ROLE_EDIT_ADDON = 'ROLE_EDIT_ADDON';
export const ROLE_REMOVE_ADDON = 'ROLE_REMOVE_ADDON';
export const ROLE_EXPORT_ADDON = 'ROLE_EXPORT_ADDON';
export const ROLE_IMPORT_ADDON = 'ROLE_IMPORT_ADDON';
export const ROLE_SHOW_CONTRACTOR = 'ROLE_SHOW_CONTRACTOR';
export const ROLE_CREATE_CONTRACTOR = 'ROLE_CREATE_CONTRACTOR';
export const ROLE_EDIT_CONTRACTOR = 'ROLE_EDIT_CONTRACTOR';
export const ROLE_REMOVE_CONTRACTOR = 'ROLE_REMOVE_CONTRACTOR';
export const ROLE_EXPORT_CONTRACTOR = 'ROLE_EXPORT_CONTRACTOR';
export const ROLE_IMPORT_CONTRACTOR = 'ROLE_IMPORT_CONTRACTOR';

export const ROLE_SHOW_MODULE_ADDONS = 'ROLE_SHOW_MODULE_ADDONS';
export const ROLE_EDIT_MODULE_ADDONS = 'ROLE_EDIT_MODULE_ADDONS';
export const ROLE_CREATE_BRAND_ACCESS_KEY = 'ROLE_CREATE_BRAND_ACCESS_KEY';
export const ROLE_EDIT_BRAND_ACCESS_KEY = 'ROLE_EDIT_BRAND_ACCESS_KEY';
export const ROLE_REMOVE_BRAND_ACCESS_KEY = 'ROLE_REMOVE_BRAND_ACCESS_KEY';
export const ROLE_SHOW_BRAND_ACCESS_KEY = 'ROLE_SHOW_BRAND_ACCESS_KEY';

export const ROLE_EXPORT_BRAND_ACCESS_KEY = 'ROLE_EXPORT_BRAND_ACCESS_KEY';
export const ROLE_SHOW_REPORT_ARCHIVE = 'ROLE_SHOW_REPORT_ARCHIVE';

export const ROLE_NOTE_DISH = 'ROLE_NOTE_DISH';
export const ROLE_EDIT_NOTE = 'ROLE_EDIT_NOTE';
export const ROLE_REMOVE_NOTE = 'ROLE_REMOVE_NOTE';
export const ROLE_NOTE_CLIENT = 'ROLE_NOTE_CLIENT';

export const ROLE_SHOW_STATISTICS_CORE_DAY_USERS =
  'ROLE_SHOW_STATISTICS_CORE_DAY_USERS';
export const ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_BAGS =
  'ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_BAGS';
export const ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_BAGS =
  'ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_BAGS';
export const ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_INCOME =
  'ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_INCOME';
export const ROLE_SHOW_STATISTICS_CORE_DAY_MONEY_BOXES =
  'ROLE_SHOW_STATISTICS_CORE_DAY_MONEY_BOXES';
export const ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_INCOME =
  'ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_INCOME';

export const ROLE_SHOW_SHOP = 'ROLE_SHOW_SHOP';
export const ROLE_SHOW_LOGISTIC_ROUTES = 'ROLE_SHOW_LOGISTIC_ROUTES';
export const ROLE_EDIT_LOGISTIC_ROUTES = 'ROLE_EDIT_LOGISTIC_ROUTES';
export const ROLE_SHOW_LOGISTIC_ROUTE_MANAGER =
  'ROLE_SHOW_LOGISTIC_ROUTE_MANAGER';
export const ROLE_EDIT_LOGISTIC_ROUTE_MANAGER =
  'ROLE_EDIT_LOGISTIC_ROUTE_MANAGER';

export const ROLE_EDIT_MODULE_SETTLEMENTS = 'ROLE_EDIT_MODULE_SETTLEMENTS';
export const ROLE_SHOW_MODULE_SETTLEMENTS = 'ROLE_SHOW_MODULE_SETTLEMENTS';
export const ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA =
  'ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA';
export const ROLE_EXPORT_STATISTICS_ECOMMERCE_CALCULATION_DATA =
  'ROLE_EXPORT_STATISTICS_ECOMMERCE_CALCULATION_DATA';
export const ROLE_SHOW_ADDRESS_REQUESTS = 'ROLE_SHOW_ADDRESS_REQUESTS';
export const ROLE_SHOW_REPORT_CHECKLIST_DIETS =
  'ROLE_SHOW_REPORT_CHECKLIST_DIETS';
export const ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS =
  'ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS';

export const ROLE_SHOW_TRANSLATION = 'ROLE_SHOW_TRANSLATION';
export const ROLE_EXPORT_TRANSLATION = 'ROLE_EXPORT_TRANSLATION';
export const ROLE_EDIT_TRANSLATION = 'ROLE_EDIT_TRANSLATION';
export const ROLE_SHOW_REPORT_MACRO_INGREDIENTS =
  'ROLE_SHOW_REPORT_MACRO_INGREDIENTS';
export const ROLE_EDIT_USE_MINI_ADDRESS_FORM =
  'ROLE_EDIT_USE_MINI_ADDRESS_FORM';
export const ROLE_SHOW_REPORT_TOTAL_BEL = 'ROLE_SHOW_REPORT_TOTAL_BEL';
export const ROLE_EDIT_HOME_PAGE = 'ROLE_EDIT_HOME_PAGE';
export const ROLE_EDIT_CMS_DIET = 'ROLE_EDIT_CMS_DIET';
export const ROLE_EDIT_CMS_DISH = 'ROLE_EDIT_CMS_DISH';
export const ROLE_EDIT_CMS_ADDON = 'ROLE_EDIT_CMS_ADDON';
export const ROLE_EDIT_CMS_ZONE = 'ROLE_EDIT_CMS_ZONE';
export const ROLE_EDIT_CMS_MENU = 'ROLE_EDIT_CMS_MENU';

export const ROLE_SHOW_KITCHEN = 'ROLE_SHOW_KITCHEN';
export const ROLE_EDIT_KITCHEN = 'ROLE_EDIT_KITCHEN';
export const ROLE_NOTE_KITCHEN = 'ROLE_NOTE_KITCHEN';

export const ROLE_SHOW_TASKS_GENERATOR = 'ROLE_SHOW_TASKS_GENERATOR';
export const ROLE_NOTE_TASKS_GENERATOR = 'ROLE_NOTE_TASKS_GENERATOR';
export const ROLE_CREATE_TASKS_GENERATOR = 'ROLE_CREATE_TASKS_GENERATOR';
export const ROLE_EDIT_TASKS_GENERATOR = 'ROLE_EDIT_TASKS_GENERATOR';

export const ROLE_SHOW_DISH_PACKHOUSE = 'ROLE_SHOW_DISH_PACKHOUSE';
export const ROLE_EDIT_DISH_PACKHOUSE = 'ROLE_EDIT_DISH_PACKHOUSE';
export const ROLE_NOTE_DISH_PACKHOUSE = 'ROLE_NOTE_DISH_PACKHOUSE';

export const ROLE_SHOW_BAGS_PACKHOUSE = 'ROLE_SHOW_BAGS_PACKHOUSE';
export const ROLE_EDIT_BAGS_PACKHOUSE = 'ROLE_EDIT_BAGS_PACKHOUSE';
export const ROLE_NOTE_BAGS_PACKHOUSE = 'ROLE_NOTE_BAGS_PACKHOUSE';

export const ROLE_SHOW_FEED = 'ROLE_SHOW_FEED';
export const ROLE_CREATE_FEED = 'ROLE_CREATE_FEED';
export const ROLE_EDIT_FEED = 'ROLE_EDIT_FEED';
export const ROLE_REMOVE_FEED = 'ROLE_REMOVE_FEED';
export const ROLE_EXPORT_FEED = 'ROLE_EXPORT_FEED';

//integrations
export const ROLE_SHOW_MODULE_GOOGLE_ANALYTICS =
  'ROLE_SHOW_MODULE_GOOGLE_ANALYTICS';
export const ROLE_SHOW_MODULE_GOOGLE_TAG_MANAGER =
  'ROLE_SHOW_MODULE_GOOGLE_TAG_MANAGER';
export const ROLE_EDIT_MODULE_HOTJAR = 'ROLE_EDIT_MODULE_HOTJAR';
export const ROLE_EDIT_MODULE_GOOGLE_ANALYTICS =
  'ROLE_EDIT_MODULE_GOOGLE_ANALYTICS';
export const ROLE_EDIT_MODULE_GOOGLE_TAG_MANAGER =
  'ROLE_EDIT_MODULE_GOOGLE_TAG_MANAGER';
export const ROLE_SHOW_MODULE_FACEBOOK_PIXEL =
  'ROLE_SHOW_MODULE_FACEBOOK_PIXEL';
export const ROLE_EDIT_MODULE_FACEBOOK_PIXEL =
  'ROLE_EDIT_MODULE_FACEBOOK_PIXEL';
export const ROLE_SHOW_MODULE_HOTJAR = 'ROLE_SHOW_MODULE_HOTJAR';
export const ROLE_SHOW_MODULE_MY_LEAD = 'ROLE_SHOW_MODULE_MY_LEAD';
export const ROLE_EDIT_MODULE_SALES_MANAGO = 'ROLE_EDIT_MODULE_SALES_MANAGO';
export const ROLE_SHOW_MODULE_SALES_MANAGO = 'ROLE_SHOW_MODULE_SALES_MANAGO';
export const ROLE_EDIT_MODULE_MY_LEAD = 'ROLE_EDIT_MODULE_MY_LEAD';
export const ROLE_SHOW_MODULE_GET_RESPONSE = 'ROLE_SHOW_MODULE_GET_RESPONSE';
export const ROLE_EDIT_MODULE_GET_RESPONSE = 'ROLE_EDIT_MODULE_GET_RESPONSE';
export const ROLE_SHOW_MODULE_IPRESSO = 'ROLE_SHOW_MODULE_IPRESSO';
export const ROLE_EDIT_MODULE_USER_COM = 'ROLE_EDIT_MODULE_USER_COM';
export const ROLE_SHOW_MODULE_USER_COM = 'ROLE_SHOW_MODULE_USER_COM';
export const ROLE_EDIT_MODULE_IPRESSO = 'ROLE_EDIT_MODULE_IPRESSO';
export const ROLE_EDIT_MODULE_CLICK_ON_METRICS =
  'ROLE_EDIT_MODULE_CLICK_ON_METRICS';
export const ROLE_SHOW_MODULE_POLLSTER_TRACK =
  'ROLE_SHOW_MODULE_POLLSTER_TRACK';
export const ROLE_SHOW_MODULE_CLICK_ON_METRICS =
  'ROLE_SHOW_MODULE_CLICK_ON_METRICS';
export const ROLE_EDIT_MODULE_POLLSTER_TRACK =
  'ROLE_EDIT_MODULE_POLLSTER_TRACK';
export const ROLE_SHOW_MODULE_BITRIX_24 = 'ROLE_SHOW_MODULE_BITRIX_24';
export const ROLE_EDIT_MODULE_BITRIX_24 = 'ROLE_EDIT_MODULE_BITRIX_24';
export const ROLE_SHOW_MODULE_TRUSTED_SHOPS = 'ROLE_SHOW_MODULE_TRUSTED_SHOPS';
export const ROLE_EDIT_MODULE_TRUSTED_SHOPS = 'ROLE_EDIT_MODULE_TRUSTED_SHOPS';
export const ROLE_SHOW_MODULE_THULIUM = 'ROLE_SHOW_MODULE_THULIUM';
export const ROLE_EDIT_MODULE_THULIUM = 'ROLE_EDIT_MODULE_THULIUM';
export const ROLE_SHOW_MODULE_OMNICONVERT = 'ROLE_SHOW_MODULE_OMNICONVERT';
export const ROLE_EDIT_MODULE_OMNICONVERT = 'ROLE_EDIT_MODULE_OMNICONVERT';
export const ROLE_SHOW_DISH_PHOTO_GALLERY = 'ROLE_SHOW_DISH_PHOTO_GALLERY';
export const ROLE_EDIT_DISH_PHOTO_GALLERY = 'ROLE_EDIT_DISH_PHOTO_GALLERY';
export const ROLE_IMPORT_INGREDIENT_PRICE = 'ROLE_IMPORT_INGREDIENT_PRICE';
export const ROLE_EXPORT_INGREDIENT_PRICE = 'ROLE_EXPORT_INGREDIENT_PRICE';
export const ROLE_SHOW_BLOG_POST = 'ROLE_SHOW_BLOG_POST';
export const ROLE_REMOVE_BLOG_POST = 'ROLE_REMOVE_BLOG_POST';
export const ROLE_EDIT_BLOG_POST = 'ROLE_EDIT_BLOG_POST';
export const ROLE_CREATE_BLOG_POST = 'ROLE_CREATE_BLOG_POST';
export const ROLE_IMPORT_INGREDIENT_TRANSLATION =
  'ROLE_IMPORT_INGREDIENT_TRANSLATION';
export const ROLE_EXPORT_INGREDIENT_TRANSLATION =
  'ROLE_EXPORT_INGREDIENT_TRANSLATION';
export const ROLE_IMPORT_DISH_TRANSLATION = 'ROLE_IMPORT_DISH_TRANSLATION';
export const ROLE_EXPORT_DISH_TRANSLATION = 'ROLE_EXPORT_DISH_TRANSLATION';
export const ROLE_SHOW_REPORT_SELECTIONS = 'ROLE_SHOW_REPORT_SELECTIONS';
export const ROLE_SHOW_COPY_BRAND_CONFIGURATION =
  'ROLE_SHOW_COPY_BRAND_CONFIGURATION';
export const ROLE_SHOW_KWG_GROUP = 'ROLE_SHOW_KWG_GROUP';
export const ROLE_CREATE_KWG_GROUP = 'ROLE_CREATE_KWG_GROUP';
export const ROLE_EDIT_KWG_GROUP = 'ROLE_EDIT_KWG_GROUP';
export const ROLE_SHOW_MODULE_SCAN_AND_SORT = 'ROLE_SHOW_MODULE_SCAN_AND_SORT';
export const ROLE_EDIT_MODULE_SCAN_AND_SORT = 'ROLE_EDIT_MODULE_SCAN_AND_SORT';
export const ROLE_REMOVE_KWG_GROUP = 'ROLE_REMOVE_KWG_GROUP';

const roles = {
  ROLE_CLIENT,
  ROLE_EMPLOYEE,
  ROLE_SHOW_CLIENT_CALL,
  ROLE_CREATE_CLIENT_CALL,
  ROLE_EDIT_CLIENT_CALL,
  ROLE_REMOVE_CLIENT_CALL,
  ROLE_EXPORT_CLIENT_CALL,
  ROLE_SHOW_MENU_PLANNING,
  ROLE_SHOW_MENU_SUMMARY,
  ROLE_CREATE_MENU_PLANNING,
  ROLE_REMOVE_MENU_PLANNING,
  ROLE_EXPORT_MENU_PLANNING,
  ROLE_AUTO_FILL_MENU_PLANNING,
  ROLE_SHOW_SIZE,
  ROLE_CREATE_SIZE,
  ROLE_EDIT_SIZE,
  ROLE_REMOVE_SIZE,
  ROLE_EXPORT_SIZE,
  ROLE_SHOW_MEAL_TYPE,
  ROLE_CREATE_MEAL_TYPE,
  ROLE_EDIT_MEAL_TYPE,
  ROLE_REMOVE_MEAL_TYPE,
  ROLE_EXPORT_MEAL_TYPE,
  ROLE_SHOW_DIET,
  ROLE_CREATE_DIET,
  ROLE_EDIT_DIET,
  ROLE_REMOVE_DIET,
  ROLE_EXPORT_DIET,
  ROLE_SHOW_VARIANT,
  ROLE_CREATE_VARIANT,
  ROLE_EDIT_VARIANT,
  ROLE_REMOVE_VARIANT,
  ROLE_EXPORT_VARIANT,
  ROLE_SHOW_INGREDIENT,
  ROLE_CREATE_INGREDIENT,
  ROLE_EDIT_INGREDIENT,
  ROLE_REMOVE_INGREDIENT,
  ROLE_EXPORT_INGREDIENT,
  ROLE_IMPORT_INGREDIENT,
  ROLE_SHOW_PRODUCT,
  ROLE_EDIT_PRODUCT,
  ROLE_SHOW_RECIPE,
  ROLE_CREATE_RECIPE,
  ROLE_SHOW_ADDITIONAL_OFFER,
  ROLE_EDIT_RECIPE,
  ROLE_REMOVE_RECIPE,
  ROLE_EXPORT_RECIPE,
  ROLE_NOTE_RECIPE,
  ROLE_SHOW_DISH,
  ROLE_CREATE_DISH,
  ROLE_EDIT_DISH,
  ROLE_SHOW_MODULE_SHOP,
  ROLE_EDIT_MODULE_SHOP,
  ROLE_REMOVE_DISH,
  ROLE_EXPORT_DISH,
  ROLE_SHOW_DICTIONARY,
  ROLE_CREATE_DICTIONARY,
  ROLE_EDIT_DICTIONARY,
  ROLE_REMOVE_DICTIONARY,
  ROLE_EXPORT_DICTIONARY,
  ROLE_SHOW_PICK_UP_POINT,
  ROLE_CREATE_PICK_UP_POINT,
  ROLE_EDIT_PICK_UP_POINT,
  ROLE_REMOVE_PICK_UP_POINT,
  ROLE_SHOW_BAG_CONTAINER,
  ROLE_CREATE_BAG_CONTAINER,
  ROLE_EDIT_BAG_CONTAINER,
  ROLE_REMOVE_BAG_CONTAINER,
  ROLE_EXPORT_BAG_CONTAINER,
  ROLE_EDIT_COMPANY,
  ROLE_SHOW_USER,
  ROLE_CREATE_USER,
  ROLE_EDIT_USER,
  ROLE_REMOVE_USER,
  ROLE_EXPORT_USER,
  ROLE_IMPORT_USER,
  ROLE_SHOW_EMPLOYEE,
  ROLE_CREATE_EMPLOYEE,
  ROLE_EDIT_EMPLOYEE,
  ROLE_REMOVE_EMPLOYEE,
  ROLE_EXPORT_EMPLOYEE,
  ROLE_IMPERSONATE_USER,
  ROLE_SHOW_BRAND,
  ROLE_SHOW_BRAND_POWER_BI,
  ROLE_CREATE_BRAND,
  ROLE_EDIT_BRAND,
  ROLE_REMOVE_BRAND,
  ROLE_EXPORT_BRAND,
  ROLE_SHOW_ROLE,
  ROLE_CREATE_ROLE,
  ROLE_EDIT_ROLE,
  ROLE_REMOVE_ROLE,
  ROLE_EXPORT_ROLE,
  ROLE_SHOW_CLIENT_METRIC,
  ROLE_REMOVE_CLIENT_METRIC,
  ROLE_SHOW_ZONE_CATEGORY,
  ROLE_CREATE_ZONE_CATEGORY,
  ROLE_EDIT_ZONE_CATEGORY,
  ROLE_REMOVE_ZONE_CATEGORY,
  ROLE_EXPORT_ZONE_CATEGORY,
  ROLE_SHOW_ZONE,
  ROLE_CREATE_ZONE,
  ROLE_EDIT_ZONE,
  ROLE_REMOVE_ZONE,
  ROLE_EXPORT_ZONE,
  ROLE_IMPORT_ZONE,
  ROLE_SHOW_COST,
  ROLE_CREATE_COST,
  ROLE_EDIT_COST,
  ROLE_REMOVE_COST,
  ROLE_EXPORT_COST,
  ROLE_IMPORT_COST,
  ROLE_SHOW_ADDRESS,
  ROLE_CREATE_ADDRESS,
  ROLE_EDIT_ADDRESS,
  ROLE_REMOVE_ADDRESS,
  ROLE_EXPORT_ADDRESS,
  ROLE_SHOW_DISCOUNT_CODE,
  ROLE_CREATE_DISCOUNT_CODE,
  ROLE_EDIT_DISCOUNT_CODE,
  ROLE_REMOVE_DISCOUNT_CODE,
  ROLE_IMPORT_DISCOUNT_CODE,
  ROLE_EXPORT_DISCOUNT_CODE,
  ROLE_SHOW_ORDER,
  ROLE_SHOW_BASKET,
  ROLE_SHOW_BAG,
  ROLE_EDIT_ORDER,
  ROLE_EXPORT_ORDER,
  ROLE_IMPORT_ORDER,
  ROLE_CREATE_ORDER,
  ROLE_SHOW_DRIVER,
  ROLE_CREATE_DRIVER,
  ROLE_EDIT_DRIVER,
  ROLE_REMOVE_DRIVER,
  ROLE_EXPORT_DRIVER,
  ROLE_SHOW_MONEY_BOX_HISTORY,
  ROLE_CREATE_MONEY_BOX_HISTORY,
  ROLE_EDIT_MONEY_BOX_HISTORY,
  ROLE_REMOVE_MONEY_BOX_HISTORY,
  ROLE_EXPORT_MONEY_BOX_HISTORY,
  ROLE_SHOW_MONEY_BOX_CONFIGURATION,
  ROLE_CREATE_MONEY_BOX_CONFIGURATION,
  ROLE_EDIT_MONEY_BOX_CONFIGURATION,
  ROLE_REMOVE_MONEY_BOX_CONFIGURATION,
  ROLE_EXPORT_MONEY_BOX_CONFIGURATION,
  ROLE_SHOW_MAIL_CONFIGURATION,
  ROLE_CREATE_MAIL_CONFIGURATION,
  ROLE_EDIT_MAIL_CONFIGURATION,
  ROLE_REMOVE_MAIL_CONFIGURATION,
  ROLE_EXPORT_MAIL_CONFIGURATION,
  ROLE_SHOW_SMS_CONFIGURATION,
  ROLE_CREATE_SMS_CONFIGURATION,
  ROLE_EDIT_SMS_CONFIGURATION,
  ROLE_REMOVE_SMS_CONFIGURATION,
  ROLE_EXPORT_SMS_CONFIGURATION,
  ROLE_SHOW_END_OF_DIET_REMINDER,
  ROLE_CREATE_END_OF_DIET_REMINDER,
  ROLE_EDIT_END_OF_DIET_REMINDER,
  ROLE_REMOVE_END_OF_DIET_REMINDER,
  ROLE_EXPORT_END_OF_DIET_REMINDER,
  ROLE_SHOW_SMS,
  ROLE_CREATE_SMS,
  ROLE_SHOW_EMAIL,
  ROLE_CREATE_EMAIL,
  ROLE_SHOW_NOTE,
  ROLE_CREATE_NOTE,
  ROLE_EXPORT_NOTE,
  ROLE_SHOW_RATE,
  ROLE_SHOW_REPORT_EMPLOYEE,
  ROLE_SHOW_REPORT_SENT_SMS,
  ROLE_SHOW_REPORT_ACCOUNTANT_INCOME,
  ROLE_SHOW_REPORT_ACCOUNTANT_NEW_ACCOUNTS,
  ROLE_SHOW_REPORT_ACCOUNTANT_DEPOSIT_AND_REFUNDS,
  ROLE_SHOW_REPORT_DISH_LIST,
  ROLE_SHOW_REPORT_DISH_STICKERS,
  ROLE_SHOW_REPORT_BAG_STICKERS,
  ROLE_SHOW_REPORT_BAG_STICKERS_MINI,
  ROLE_SHOW_REPORT_DISHES_CARD,
  ROLE_SHOW_REPORT_RECIPES_CARD,
  ROLE_SHOW_REPORT_RECIPES_INGREDIENTS,
  ROLE_SHOW_REPORT_FOR_DRIVER,
  ROLE_SHOW_REPORT_SHOPPING,
  ROLE_SHOW_REPORT_CONTAINERS,
  ROLE_SHOW_REPORT_CONTAINERS_DETAIL,
  ROLE_SHOW_REPORT_EMPLOYEE_TIME_ENTRIES,
  ROLE_SHOW_REPORT_USAGE_DISCOUNT_CODES,
  ROLE_SHOW_REPORT_MONEY_BOX_TOP_UP,
  ROLE_SHOW_REPORT_WASTE,
  ROLE_SHOW_REPORT_INCOME_SUMMARY,
  ROLE_SHOW_REPORT_FOOD_COST,
  ROLE_SHOW_REPORT_FOOD_COST_BAGS,
  ROLE_SHOW_REPORT_PURCHASED_ADDONS,
  ROLE_SHOW_REPORT_PURCHASED_ADDONS_BY_CLIENT,
  ROLE_SHOW_REPORT_ADVANCED_DISH_PDF,
  ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION,
  ROLE_SHOW_REPORT_ACTIVE_DIET,
  ROLE_SHOW_REPORT_ACTIVE_DIET_TABLE,
  ROLE_SHOW_REPORT_ORDERED_DIET,
  ROLE_SHOW_REPORT_PERSONAL_PICKUPS,
  ROLE_SHOW_REPORT_PACKERS,
  ROLE_SHOW_REPORT_RECIPE_STICKERS,
  ROLE_SHOW_SYSTEM_ADVANCED_DISH,
  ROLE_SHOW_NOTIFICATION,
  ROLE_CREATE_NOTIFICATION,
  ROLE_EDIT_NOTIFICATION,
  ROLE_REMOVE_NOTIFICATION,
  ROLE_EXPORT_NOTIFICATION,
  ROLE_SHOW_SHOW_MENU_PAGE,
  ROLE_EDIT_SHOW_MENU_PAGE,
  ROLE_SHOW_PAGE,
  ROLE_CREATE_PAGE,
  ROLE_EDIT_PAGE,
  ROLE_REMOVE_PAGE,
  ROLE_EXPORT_PAGE,
  ROLE_SHOW_MASS_SMS,
  ROLE_CREATE_MASS_SMS,
  ROLE_EDIT_MASS_SMS,
  ROLE_REMOVE_MASS_SMS,
  ROLE_EXPORT_MASS_SMS,
  ROLE_SHOW_MASS_MAIL,
  ROLE_CREATE_MASS_MAIL,
  ROLE_EDIT_MASS_MAIL,
  ROLE_EXPORT_MASS_MAIL,
  ROLE_SHOW_MASS_ACTION_DIET_ELEMENTS,
  ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_REMOVE_IMPORTER_TASK,
  ROLE_EXPORT_IMPORTER_TASK,
  ROLE_CREATE_PACKAGE,
  ROLE_EDIT_PACKAGE,
  ROLE_SHOW_PACKAGE,
  ROLE_REMOVE_PACKAGE,
  ROLE_EXPORT_PACKAGE,
  ROLE_SHOW_PACKAGE_LINE_FILTER,
  ROLE_EDIT_PACKAGE_LINE_FILTER,
  ROLE_REMOVE_PACKAGE_LINE_FILTER,
  ROLE_CREATE_PACKAGE_LINE_FILTER,
  ROLE_SHOW_OVERPRODUCTION,
  ROLE_CREATE_OVERPRODUCTION,
  ROLE_EDIT_OVERPRODUCTION,
  ROLE_REMOVE_OVERPRODUCTION,
  ROLE_EXPORT_OVERPRODUCTION,
  ROLE_SHOW_SUB_BRAND,
  ROLE_CREATE_SUB_BRAND,
  ROLE_EDIT_SUB_BRAND,
  ROLE_REMOVE_SUB_BRAND,
  ROLE_EXPORT_SUB_BRAND,
  ROLE_SHOW_REPORT_OVERPRODUCTION,
  ROLE_SHOW_HOVER,
  ROLE_EDIT_HOVER,
  ROLE_SHOW_WASTE,
  ROLE_CREATE_WASTE,
  ROLE_EDIT_WASTE,
  ROLE_REMOVE_WASTE,
  ROLE_EXPORT_WASTE,
  ROLE_SHOW_SEO,
  ROLE_EDIT_SEO,
  ROLE_SHOW_STATISTICS_DIET,
  ROLE_SHOW_STATISTICS_BAGS,
  ROLE_SHOW_STATISTICS_INCOMES,
  ROLE_SHOW_STATISTICS_SEX,
  ROLE_SHOW_STATISTICS_KNOW_ABOUT,
  ROLE_SHOW_MAILING,
  ROLE_EDIT_MAILING,
  ROLE_SHOW_SMSING,
  ROLE_EDIT_SMSING,
  ROLE_SHOW_REPORT_CHECKLIST_RECIPES,
  ROLE_SHOW_REPORT_CHECKLIST_DISHES,
  ROLE_SHOW_ADDON,
  ROLE_CREATE_ADDON,
  ROLE_EDIT_ADDON,
  ROLE_REMOVE_ADDON,
  ROLE_EXPORT_ADDON,
  ROLE_IMPORT_ADDON,
  ROLE_SHOW_CONTRACTOR,
  ROLE_CREATE_CONTRACTOR,
  ROLE_EDIT_CONTRACTOR,
  ROLE_REMOVE_CONTRACTOR,
  ROLE_EXPORT_CONTRACTOR,
  ROLE_IMPORT_CONTRACTOR,
  ROLE_SHOW_MODULE_ADDONS,
  ROLE_EDIT_MODULE_ADDONS,
  ROLE_CREATE_BRAND_ACCESS_KEY,
  ROLE_REMOVE_BRAND_ACCESS_KEY,
  ROLE_SHOW_BRAND_ACCESS_KEY,
  ROLE_EXPORT_BRAND_ACCESS_KEY,
  ROLE_SHOW_REPORT_ARCHIVE,
  ROLE_NOTE_DISH,
  ROLE_EDIT_NOTE,
  ROLE_REMOVE_NOTE,
  ROLE_NOTE_CLIENT,
  ROLE_SHOW_STATISTICS_CORE_DAY_USERS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_BAGS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_DAY_INCOME,
  ROLE_SHOW_STATISTICS_CORE_DAY_MONEY_BOXES,
  ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_INCOME,
  ROLE_SHOW_STATISTICS_ECOMMERCE_YEAR_BAGS,
  ROLE_SHOW_SHOP,
  ROLE_SHOW_LOGISTIC_ROUTES,
  ROLE_EDIT_LOGISTIC_ROUTES,
  ROLE_SHOW_LOGISTIC_ROUTE_MANAGER,
  ROLE_EDIT_LOGISTIC_ROUTE_MANAGER,
  ROLE_SHOW_MODULE_SETTLEMENTS,
  ROLE_EDIT_MODULE_SETTLEMENTS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA,
  ROLE_EXPORT_STATISTICS_ECOMMERCE_CALCULATION_DATA,
  ROLE_SHOW_ADDRESS_REQUESTS,
  ROLE_SHOW_REPORT_CHECKLIST_DIETS,
  ROLE_SHOW_REPORT_CHECKLIST_ALTERED_DIETS,
  ROLE_SHOW_TRANSLATION,
  ROLE_EXPORT_TRANSLATION,
  ROLE_EDIT_TRANSLATION,
  ROLE_SHOW_REPORT_MACRO_INGREDIENTS,
  ROLE_EDIT_USE_MINI_ADDRESS_FORM,
  ROLE_SHOW_REPORT_TOTAL_BEL,
  ROLE_EDIT_HOME_PAGE,
  ROLE_EDIT_CMS_DIET,
  ROLE_EDIT_CMS_DISH,
  ROLE_EDIT_CMS_ADDON,
  ROLE_EDIT_CMS_ZONE,
  ROLE_EDIT_CMS_MENU,
  ROLE_SHOW_KITCHEN,
  ROLE_EDIT_KITCHEN,
  ROLE_NOTE_KITCHEN,
  ROLE_SHOW_TASKS_GENERATOR,
  ROLE_NOTE_TASKS_GENERATOR,
  ROLE_CREATE_TASKS_GENERATOR,
  ROLE_EDIT_TASKS_GENERATOR,
  ROLE_SHOW_DISH_PACKHOUSE,
  ROLE_EDIT_DISH_PACKHOUSE,
  ROLE_NOTE_DISH_PACKHOUSE,
  ROLE_SHOW_BAGS_PACKHOUSE,
  ROLE_EDIT_BAGS_PACKHOUSE,
  ROLE_NOTE_BAGS_PACKHOUSE,
  ROLE_SHOW_FEED,
  ROLE_CREATE_FEED,
  ROLE_EDIT_FEED,
  ROLE_REMOVE_FEED,
  ROLE_EXPORT_FEED,
  ROLE_SHOW_MODULE_GOOGLE_ANALYTICS,
  ROLE_SHOW_MODULE_GOOGLE_TAG_MANAGER,
  ROLE_SHOW_MODULE_HOTJAR,
  ROLE_EDIT_MODULE_GOOGLE_ANALYTICS,
  ROLE_EDIT_MODULE_GOOGLE_TAG_MANAGER,
  ROLE_SHOW_MODULE_FACEBOOK_PIXEL,
  ROLE_EDIT_MODULE_FACEBOOK_PIXEL,
  ROLE_EDIT_MODULE_HOTJAR,
  ROLE_SHOW_MODULE_MY_LEAD,
  ROLE_EDIT_MODULE_SALES_MANAGO,
  ROLE_SHOW_MODULE_SALES_MANAGO,
  ROLE_EDIT_MODULE_MY_LEAD,
  ROLE_SHOW_MODULE_GET_RESPONSE,
  ROLE_EDIT_MODULE_GET_RESPONSE,
  ROLE_SHOW_MODULE_IPRESSO,
  ROLE_EDIT_MODULE_USER_COM,
  ROLE_SHOW_MODULE_USER_COM,
  ROLE_EDIT_MODULE_IPRESSO,
  ROLE_EDIT_MODULE_CLICK_ON_METRICS,
  ROLE_SHOW_MODULE_POLLSTER_TRACK,
  ROLE_SHOW_MODULE_CLICK_ON_METRICS,
  ROLE_EDIT_MODULE_POLLSTER_TRACK,
  ROLE_SHOW_MODULE_BITRIX_24,
  ROLE_EDIT_MODULE_BITRIX_24,
  ROLE_SHOW_MODULE_TRUSTED_SHOPS,
  ROLE_EDIT_MODULE_TRUSTED_SHOPS,
  ROLE_SHOW_MODULE_THULIUM,
  ROLE_EDIT_MODULE_THULIUM,
  ROLE_SHOW_MODULE_OMNICONVERT,
  ROLE_EDIT_MODULE_OMNICONVERT,
  ROLE_SHOW_DISH_PHOTO_GALLERY,
  ROLE_EDIT_DISH_PHOTO_GALLERY,
  ROLE_IMPORT_INGREDIENT_PRICE,
  ROLE_EXPORT_INGREDIENT_PRICE,
  ROLE_SHOW_BLOG_POST,
  ROLE_REMOVE_BLOG_POST,
  ROLE_EDIT_BLOG_POST,
  ROLE_CREATE_BLOG_POST,
  ROLE_IMPORT_INGREDIENT_TRANSLATION,
  ROLE_EXPORT_INGREDIENT_TRANSLATION,
  ROLE_IMPORT_DISH_TRANSLATION,
  ROLE_EXPORT_DISH_TRANSLATION,
  ROLE_SHOW_REPORT_DISPOSAL,
  ROLE_SHOW_REPORT_SELECTIONS,
  ROLE_SHOW_COPY_BRAND_CONFIGURATION,
  ROLE_SHOW_KWG_GROUP,
  ROLE_CREATE_KWG_GROUP,
  ROLE_EDIT_KWG_GROUP,
  ROLE_REMOVE_KWG_GROUP,
  ROLE_SHOW_MODULE_SCAN_AND_SORT,
  ROLE_EDIT_MODULE_SCAN_AND_SORT,
  ROLE_SHOW_MODULE_MENU_SELECTION_ASSISTANT,
};

export default roles;
