import roles from 'helpers/roles';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

const IncomingSubscriptions = () => {
  const { t } = useTranslation();
  const title = t('reports.reportIncomingSubscriptions');

  return (
    <BrandDateSubbrandsReport
      title={title}
      url="reports/incoming-subscription"
      mimeType="application/vnd.ms-excel"
      fileName={getReportFileName(title)}
      role={roles.ROLE_SHOW_REPORT_INCOMING_SUBSCRIPTION}
      useDateRange={true}
      useSubBrands={false}
      showMultiplier={false}
      archiveReportNames={['INCOMING_SUBSCRIPTION']}
    />
  );
};

export default IncomingSubscriptions;
