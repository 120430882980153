import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Person from '@material-ui/icons/Person';
import ListItemText from '@material-ui/core/ListItemText';
import makeStyles from '@material-ui/styles/makeStyles';

import { LINK_BORDER_RADIUS, LINK_FOCUSED_BG_COLOR } from '../constants/styles';
import SidebarItem from '../SidebarItem/SidebarItem';

const useStyles = makeStyles({
  listItemText: {
    color: 'inherit',
    margin: '0',
    fontSize: '12px',
    transform: 'translate3d(0, 0, 0)',
    opacity: '1',
    transition: 'transform 300ms ease 0s, opacity 300ms ease 0s',
    whiteSpace: 'nowrap',
    lineHeight: '28px',
  },
  listItemTextMini: {
    transform: 'translate3d(-25px, 0, 0)',
    opacity: '0',
  },
  logoMini: {
    transition: 'all 300ms linear',
    opacity: 1,
    float: 'left',
    textAlign: 'center',
    width: '30px',
    maxHeight: '30px',
    margin: '7px 18px 0 25px',
  },
  userImg: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
  },
  navLink: {
    margin: '0 15px',
    padding: '6px 15px',
    transition: 'all 300ms linear',
    borderRadius: LINK_BORDER_RADIUS,
    display: 'block',
    '&:hover': {
      outline: 'none',
      backgroundColor: LINK_FOCUSED_BG_COLOR,
      boxShadow: 'none',
    },
    '&,&:hover, &:focus': {
      color: 'inherit',
    },
  },
});

const UserBar = () => {
  const classes = useStyles();

  const { user } = useSelector(({ Auth: { user, selectedBrand, links } }) => ({
    user,
  }));
  const { isSidebarMiniModeActive, isSidebarHovered } = useSelector(
    state => state.SidebarStatus
  );

  return (
    <SidebarItem>
      <div className={classes.logoMini}>
        {user.image ? (
          <img
            className={classes.userImg}
            alt=""
            src={process.env.REACT_APP_STATIC_FILES_CDN + user.imageUrl}
          />
        ) : (
          <Person />
        )}
      </div>
      <NavLink to="/admin/my-profile" className={classes.navLink}>
        <ListItemText
          primary={
            <b data-cy="sidebar_personal-info">
              {user.firstName} {user.lastName}
            </b>
          }
          disableTypography={true}
          className={classNames(classes.listItemText, {
            [classes.listItemTextMini]:
              isSidebarMiniModeActive && !isSidebarHovered,
          })}
        />
      </NavLink>
    </SidebarItem>
  );
};

export default UserBar;
