import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  wrapper: { display: 'flex' },
  select: { width: '100%', height: '30px', marginLeft: '10px' },
});

const DiscountFilterWithPercentage = ({ onFilterChange, filterValue }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <input
        type="number"
        value={filterValue?.value || ''}
        onChange={e => {
          onFilterChange({
            value: e.target.value,
            isPercentage: filterValue?.isPercentage,
          });
        }}
      />

      <select
        className={classes.select}
        onChange={e => {
          onFilterChange({
            value: filterValue?.value || '',
            isPercentage: e.target.value,
          });
        }}
        value={filterValue?.isPercentage || ''}
      >
        <option value="">{t('common.filter.all')}</option>
        <option value={true}>{t('common.percentage')}</option>
        <option value={false}>{t('common.amount')}</option>
      </select>
    </div>
  );
};

export default DiscountFilterWithPercentage;
